import Box from 'ReusableComponents/Box';
import React, { useEffect, useState } from 'react';
import ProductCard from 'ReusableComponents/ProductCard';
import StyledLink from 'ReusableComponents/Link';
import { useMedia } from 'helpers';
import { DESIGN_CONFIG, STRING_CONFIG } from 'utils';
import EmptyState from 'ReusableComponents/EmptyState';
import Skeleton from 'react-loading-skeleton';

function CategorySection({ name, one, two, three, data, loading }) {
  const MQ450 = useMedia('(max-width:450px)');

  const [reDirectRoute, setReDirectRoute] = useState('');

  useEffect(() => {
    if (one) {
      setReDirectRoute('most-viewed-products');
    } else if (two) {
      setReDirectRoute('most-reviewed-products');
    } else if (three) {
      setReDirectRoute('most-upvoted-products');
    }
  }, []);

  let content;

  if (data && data.length > 0) {
    content = (
      <ul className='category-section__wrapper'>
        {data.map((productData) => (
          <ProductCard key={productData?.id} productData={productData} />
        ))}
      </ul>
    );
  } else {
    content = (
      <EmptyState
        title={STRING_CONFIG.inPage.emptyState.txtNoProductsTitle}
        subTitle={STRING_CONFIG.inPage.emptyState.txtNoProductsSubtitle}
      />
    );
  }

  return (
    <section
      aria-labelledby={name}
      className='category-section'
      style={{ width: '100%' }}
    >
      <Box
        display='flex'
        justifyContent='space-between'
        marginTop={MQ450 ? '1rem' : '2.5rem'}
      >
        <h2 id={name} className='category-section__title'>
          {name}
        </h2>
        <StyledLink
          color={DESIGN_CONFIG.color.primary.primaryMain}
          display={data?.length > 4 ? 'block' : 'none'}
          fontSize={
            MQ450 ? '.875rem' : DESIGN_CONFIG.typography.common.webLink.fontSize
          }
          fontWeight={
            MQ450 ? 600 : DESIGN_CONFIG.typography.common.webLink.fontWeight
          }
          to={`/${reDirectRoute}`}
        >
          {STRING_CONFIG.inPage.common.lnkViewAll}
        </StyledLink>
      </Box>
      {loading ? (
        <ul className='category-section__wrapper'>
          <Skeleton height={MQ450 ? '12.5rem' : '16.625rem'} />
          <Skeleton height={MQ450 ? '12.5rem' : '16.625rem'} />
          <Skeleton height={MQ450 ? '12.5rem' : '16.625rem'} />
          <Skeleton height={MQ450 ? '12.5rem' : '16.625rem'} />
        </ul>
      ) : (
        content
      )}
    </section>
  );
}

export default CategorySection;
