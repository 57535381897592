import * as actionType from './actionType';

const initialState = {
  activeIndex: 1,
  adTray: {
    amount: 0,
    show: true,
    status: null,
  },
  token: '',
  transactions: [],
  transactionAPICall: true,
  showSuccess: false,
  user: {},
};

// eslint-disable-next-line default-param-last
export default (state = initialState, action) => {
  switch (action.type) {
    case actionType.ACTIVE_INDEX: {
      return {
        ...state,
        activeIndex: action.payload,
      };
    }
    case actionType.DONATE: {
      const sortedTransactions = action.payload.sort(
        (a, b) => new Date(b.transaction_date) - new Date(a.transaction_date)
      );

      const latestTransaction =
        sortedTransactions.length > 0 ? sortedTransactions[0] : null;
      let newAdTray = { ...state.adTray };

      if (latestTransaction && latestTransaction.status_id === 3) {
        const paymentDate = new Date(latestTransaction.transaction_date);

        const days = Math.ceil(latestTransaction.transaction_amount / 10);

        const adValidity = new Date(
          paymentDate.setDate(paymentDate.getDate() + days)
        );

        const currentDate = new Date();
        if (currentDate < adValidity) {
          newAdTray = {
            amount: latestTransaction.transaction_amount,
            show: false,
            status: 3,
          };
        } else {
          newAdTray = {
            amount: 0,
            show: true,
            status: null,
          };
        }
      } else {
        newAdTray = {
          show: true,
          status: latestTransaction ? latestTransaction.status_id : null,
        };
      }
      return {
        ...state,
        adTray: newAdTray,
        transactions: sortedTransactions,
      };
    }
    case actionType.DELETE_PROFILE_IMAGE: {
      return {
        ...state,
        user: {
          ...state.user,
          image: null,
        },
      };
    }
    case actionType.SIGN_OUT: {
      return {
        ...state,
        token: '',
        user: {},
      };
    }
    case actionType.SHOW_SUCCESS: {
      return {
        ...state,
        showSuccess: action.payload,
      };
    }
    case actionType.TRANSACTION_API_CALL: {
      return {
        ...state,
        transactionAPICall: action.payload,
      };
    }
    case actionType.TOKEN: {
      return {
        ...state,
        token: action.payload,
      };
    }
    case actionType.USER_DETAILS: {
      return {
        ...state,
        user: action.payload,
      };
    }
    case actionType.UPDATE_PROFILE_IMAGE: {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    }
    case actionType.UPDATE_ADTRAY_STATUS: {
      return {
        ...state,
        adTray: {
          ...state.adTray,
          status: action.payload,
        },
      };
    }
    case actionType.UPDATE_USER_DETAILS: {
      return {
        ...state,
        user: {
          ...state.user,
          firstName: action.payload.first_name,
          lastName: action.payload.last_name,
          phone: action.payload.phone,
        },
      };
    }
    default: {
      return state;
    }
  }
};
