import React, { useEffect, useState } from 'react';
import Box from 'ReusableComponents/Box';
import { activeIndex } from 'store/auth/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import StyledLink from 'ReusableComponents/Link';
import './style.css';
import { articleData } from 'Pages/Articles/data';
import { DESIGN_CONFIG } from 'utils';
import Dropdown from './Dropdown';

function Sidebar() {
  const index = useSelector(({ auth }) => auth.activeIndex);

  const activeArticleId = useSelector(
    (state) => state.platform.activeArticleId || 1
  );

  const history = useHistory();

  const dispatch = useDispatch();

  const [headings, setHeadings] = useState([]);

  useEffect(() => {
    const allTitles = Object.values(articleData).map(
      (article) => article.title
    );

    setHeadings(allTitles);
  }, []);

  useEffect(() => {
    if (history.location.pathname === '/') dispatch(activeIndex(1));
    else if (history.location.pathname === '/browse') dispatch(activeIndex(2));
    else if (history.location.pathname === '/articles')
      dispatch(activeIndex(3));
    else {
      dispatch(activeIndex(-1));
    }
  }, [history]);

  const handleChangeMenu = (i) => {
    dispatch(activeIndex(i));
  };

  return (
    <Box
      background={DESIGN_CONFIG.color.neutral.neutral500}
      display='flex'
      flexDirection='column'
      marginTop='.75rem'
    >
      <ul className='hamburger-menu-list' role='menubar'>
        <li
          onClick={() => {
            handleChangeMenu(1);

            history.push('/home');
          }}
          role='button' // eslint-disable-line jsx-a11y/no-noninteractive-element-to-interactive-role
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              handleChangeMenu(1);

              history.push('/home');
            }
          }}
        >
          <StyledLink
            color={
              index === 1
                ? DESIGN_CONFIG.color.primary.primaryMain
                : DESIGN_CONFIG.color.text.text200
            }
            fontSize='.875rem'
            fontWeight={400}
            hoverColor={DESIGN_CONFIG.color.primary.primaryMain}
            role='menuitem'
            to='/home'
          >
            Home
          </StyledLink>
        </li>
        <li
          onClick={() => {
            handleChangeMenu(2);

            history.push('/browse');
          }}
          role='button' // eslint-disable-line jsx-a11y/no-noninteractive-element-to-interactive-role
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              handleChangeMenu(2);

              history.push('/browse');
            }
          }}
        >
          <StyledLink
            color={
              index === 2
                ? DESIGN_CONFIG.color.primary.primaryMain
                : DESIGN_CONFIG.color.text.text200
            }
            fontSize='.875rem'
            fontWeight={400}
            hoverColor={DESIGN_CONFIG.color.primary.primaryMain}
            role='menuitem'
            to='/browse'
          >
            Browse
          </StyledLink>
        </li>
        <li>
          <Dropdown
            index={index}
            activeArticleId={activeArticleId}
            options={headings}
          />
        </li>
      </ul>
    </Box>
  );
}

export default Sidebar;
