import React, { useEffect, useState } from 'react';
import Header from 'Pages/Home/Components/Trending/Header';
import TopBar from 'Pages/Product/Topbar';
import Footer from 'Pages/Footer';
import TopbarM from 'Pages/Product/Topbar/TopbarM';
import { Helmet } from 'react-helmet';
import { useMedia } from 'helpers';
import './style.css';
import { getCategoryProductList } from 'api/platformAnalyticsRequest';
import { STRING_CONFIG } from 'utils';
import { useAdTray } from 'contexts/AdTrayContext';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from 'store/platform/actions';
import CategorySection from './Components/CategorySection';

function Home() {
  const [productData, setProductData] = useState(null);

  const isOnline = useSelector((state) => state.platform.isOnline);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  const smallDevice = useMedia('(max-width:450px)');

  const { setShowAdTray } = useAdTray();

  useEffect(() => {
    setShowAdTray(false);
  }, []);

  const fetchProductList = async () => {
    try {
      const { data } = await getCategoryProductList('C001');
      if (data.success === true) {
        setProductData(data);
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      }
      if (err.response || err.request) {
        dispatch(
          showToast(
            STRING_CONFIG.inPage.toastMessages.txtErrorSomethingWentWrong,
            'error',
            null,
            true,
            true
          )
        );
      } else {
        // eslint-disable-next-line no-console
        console.error('Unexpected error:', err);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProductList();
  }, [isOnline]);

  return (
    <>
      {smallDevice ? <TopbarM /> : <TopBar />}
      <div className='top-page-container'>
        <main className='home'>
          <Header />
          <div style={{ marginBottom: '2rem' }}>
            <CategorySection
              data={productData?.most_viewed_products}
              loading={loading}
              name={STRING_CONFIG.inPage.home.txtSectionThreeTitle}
              one
            />
            <CategorySection
              data={productData?.most_reviewed_products}
              loading={loading}
              name={STRING_CONFIG.inPage.home.txtSectionTwoTitle}
              two
            />
            <CategorySection
              data={productData?.most_upvoted_products}
              loading={loading}
              name={STRING_CONFIG.inPage.home.txtSectionFourTitle}
              three
            />
          </div>
        </main>
      </div>
      <Footer />
      <Helmet>
        <title>{STRING_CONFIG.inPage.platform.txtHeaderReviewDale}</title>
        <meta
          name='description'
          content="With the vast number of options and features available today, we understand that choosing a phone can be tricky. That's why we created ReviewDale: A Product Community, to make it easier for you and everyone to share experiences with various phones, so go ahead and indulge!"
        />
        <meta name='keywords' content='Product, Reviews, Genuine Reviews' />
      </Helmet>
    </>
  );
}

export default Home;
