/* eslint-disable max-len */
import axios from 'axios';
import { store } from 'store';
import { ENDPOINTS } from './endpoints';

// -----***** DEPLOYMENT SETUP *****-----

// For deployment (default): Uses the deployed frontend URL.
// Uncomment the local testing setup below when using the dev server,
// and **comment this out** before testing locally.

// -----***** DEPLOYMENT SETUP *****-----

const API = axios.create({
  baseURL: `${process.env.REACT_APP_FRONTEND_URL}/user/`,
});

// -----***** LOCAL TESTING SETUP *****-----

// Local testing (Uncomment this for local development and **comment the above API**):
// Before pushing to the repo, make sure to comment this out and use the deployment setup.

// -----***** LOCAL TESTING SETUP *****-----

// const API = axios.create({
//   baseURL: `http://${process.env.REACT_APP_SERVICES_USER_ADDRESS}:${process.env.REACT_APP_SERVICES_USER_PORT}/`,
// });

API.interceptors.request.use(
  (config) => {
    const { isOnline } = store.getState().platform;

    if (!isOnline) {
      return Promise.reject(
        new axios.Cancel('Request blocked: No Internet Connection')
      );
    }

    return config;
  },
  (error) => Promise.reject(error)
);

const deleteProfilePicture = (token) =>
  API.delete(ENDPOINTS.delete_user_image, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

const deleteUserAccount = () => API.delete(ENDPOINTS.delete_user_account);

const getUserIP = () => API.get(ENDPOINTS.get_user_ip);

const getUserReviews = (page, size, token) =>
  API.get(ENDPOINTS.get_reviews_for_loggedin_user(page, size), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

const getProductsUpvotedByUser = (page, size, token) =>
  API.get(ENDPOINTS.get_product_liked_for_loggedin_user(page, size), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

const gerUserProfileData = (page, size, token, username) =>
  API.get(ENDPOINTS.get_profile_data_by_username(page, size, username), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

const followUser = (payload, token) =>
  API.post(ENDPOINTS.follow_user, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

const reportUser = (payload, token) =>
  API.post(ENDPOINTS.report_user, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

const updateOAuthMoreDetails = (payload) =>
  API.put(ENDPOINTS.update_oauth_more_details, payload);

const uploadProfilePicture = (token, payload) =>
  API.post(ENDPOINTS.upload_user_image, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  });

export {
  deleteProfilePicture,
  deleteUserAccount,
  followUser,
  getUserIP,
  getUserReviews,
  getProductsUpvotedByUser,
  gerUserProfileData,
  reportUser,
  updateOAuthMoreDetails,
  uploadProfilePicture,
};
