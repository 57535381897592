import React, { useEffect } from 'react';
import Routing from 'Routes';
import InProgress from 'Pages/InProgress';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'App.css';
import { SETTING_CONFIG, STRING_CONFIG } from 'utils';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useDispatch } from 'react-redux';
import { hideToast, setOnlineStatus, showToast } from 'store/platform/actions';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const handleOnlineStatusChange = () => {
      if (navigator.onLine) {
        dispatch(setOnlineStatus(true));

        dispatch(hideToast());
      } else {
        dispatch(
          showToast(
            STRING_CONFIG.inPage.toastMessages.txtNoInternet, // message
            'error', // type
            null, // duration
            true, // presistent
            false // closable
          )
        );

        dispatch(setOnlineStatus(false));
      }
    };

    window.addEventListener('online', handleOnlineStatusChange);

    window.addEventListener('offline', handleOnlineStatusChange);

    handleOnlineStatusChange();

    return () => {
      window.removeEventListener('online', handleOnlineStatusChange);

      window.removeEventListener('offline', handleOnlineStatusChange);
    };
  }, [dispatch]);

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      {SETTING_CONFIG.campaign.CP001.isActive ? <InProgress /> : <Routing />}
    </GoogleOAuthProvider>
  );
}

export default App;
