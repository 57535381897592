import React, { useState, useEffect } from 'react';
import { DESIGN_CONFIG } from 'utils';

function RangeInput({ setTransactionAmount }) {
  const steps = [5, 25, 65, 450, 999];

  const [valueIndex, setValueIndex] = useState(0);

  useEffect(() => {
    setTransactionAmount(steps[valueIndex]);
  }, [valueIndex]);

  // Handle slider input change
  const handleInputChange = (e) => {
    const newValueIndex = parseInt(e.target.value, 10);

    setValueIndex(newValueIndex);
  };

  return (
    <div style={{ position: 'relative', width: '100%', height: '16px' }}>
      <div
        style={{
          backgroundColor: `${DESIGN_CONFIG.color.neutral.neutral100}`,
          borderRadius: '40px',
          height: '100%',
          left: 0,
          position: 'absolute',
          top: 0,
          width: '100%',
          zIndex: 1,
        }}
      />
      <div
        style={{
          background: `${DESIGN_CONFIG.color.gradient.gradientRangeInput}`,
          borderRadius: '40px',
          height: '100%',
          left: 0,
          position: 'absolute',
          top: 0,
          width: `${(valueIndex / (steps.length - 1)) * 100}%`,
          zIndex: 2,
        }}
      />
      <input
        className='rangeInput'
        min={0}
        max={steps.length - 1}
        onChange={handleInputChange}
        step={1}
        type='range'
        value={valueIndex}
        style={{
          position: 'relative',
          zIndex: 3,
          width: '100%',
          appearance: 'none',
          background: 'transparent',
        }}
      />
      <style>
        {`
        .rangeInput::-webkit-slider-runnable-track {
          height: 16px;
          background: transparent;
        }
        .rangeInput::-webkit-slider-thumb {
          -webkit-appearance: none;
          appearance: none;
          height: 1.5rem;
          width: 1.5rem;
          background-image: url('/assets/fireThumb.svg');
          background-size: cover;
          border-radius: 50%;
          cursor: pointer;
          margin-top: -4px;
        }
        .rangeInput::-moz-range-thumb {
          height: 1.5rem;
          width: 1.5rem;
          background-image: url('/assets/fireThumb.svg');
          background-size: cover;
          border-radius: 50%;
          cursor: pointer;
        }
        .rangeInput::-ms-thumb {
          height: 1.5rem;
          width: 1.5rem;
          background-image: url('/assets/fireThumb.svg');
          background-size: cover;
          border-radius: 50%;
          cursor: pointer;
        }
      `}
      </style>
    </div>
  );
}

export default RangeInput;
