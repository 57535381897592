import React, { useEffect, useRef, useState } from 'react';
import Box from 'ReusableComponents/Box';
import Text from 'ReusableComponents/Text';
import Button from 'ReusableComponents/Button';
import Sidebar from 'Pages/Home/Components/Sidebar';
import { useHistory } from 'react-router';
import Hamburger from 'assets/Hamburger.svg';
import { useSelector } from 'react-redux';
import { DESIGN_CONFIG, STRING_CONFIG } from 'utils';
import UserDisplayPicture from './UserDisplayPicture';
import './style.css';

function TopbarM() {
  const history = useHistory();

  const authToken = useSelector(({ auth }) => auth.token);

  const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);

  const hamburgerMenuRef = useRef(null);

  const overlayRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        overlayRef.current &&
        hamburgerMenuRef.current &&
        !hamburgerMenuRef.current.contains(event.target) &&
        overlayRef.current.contains(event.target)
      ) {
        setShowHamburgerMenu(false);
      }
    };

    if (showHamburgerMenu) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [showHamburgerMenu]);

  return (
    <nav className='navbar-small'>
      <button
        className='hamburger-button'
        cursor='pointer'
        onClick={() => setShowHamburgerMenu(!showHamburgerMenu)}
        type='button'
      >
        <img
          alt='hamburger'
          aria-hidden
          height='24px'
          src={Hamburger}
          width='24px'
        />
      </button>
      <div
        className={`overlay ${showHamburgerMenu ? 'expanded' : ''}`}
        ref={overlayRef}
      >
        <nav
          aria-label='main menu'
          className={`hamburger-menu ${showHamburgerMenu ? 'expanded' : ''}`}
          ref={hamburgerMenuRef}
        >
          <Box
            alignItems='center'
            display='flex'
            gap='.75rem'
            margin='.5rem 1rem'
          >
            <img
              alt='ReviewDale'
              height={28}
              src='/assets/logos/reviewdaleMain.svg'
              width={168}
            />
          </Box>
          <Sidebar />
        </nav>
      </div>
      <Box
        alignItems='center'
        b={`1px solid ${DESIGN_CONFIG.color.neutral.neutral100}`}
        borderRadius='4px'
        display='flex'
        h='3rem'
        justifyContent='space-between'
        onClick={() => history.push('/search')}
        padding='0 .75rem 0 1rem'
        w='14.25rem'
      >
        <Text
          fontSize='.875rem'
          fontWeight={400}
          margin={0}
          textColor={DESIGN_CONFIG.color.text.text50}
        >
          {STRING_CONFIG.inPage.common.lnkNavBarSearch}
        </Text>
        <img
          alt='search'
          height={24}
          src='/icons/searchSmallScreen.svg'
          width={24}
        />
      </Box>
      {authToken ? (
        <UserDisplayPicture />
      ) : (
        <Button
          alignItems='center'
          background='transparent'
          border='none'
          cursor='pointer'
          data-cy='submit'
          display='flex'
          h='1.5rem'
          justifyContent='center'
          onClick={() => history.push('/sign-in')}
          outline='transparent'
          padding={0}
          w='3rem'
        >
          <Text
            fontSize='.875rem'
            fontWeight={700}
            margin={0}
            textColor={DESIGN_CONFIG.color.primary.primaryMain}
          >
            {STRING_CONFIG.inPage.common.txtSignIn}
          </Text>
        </Button>
      )}
    </nav>
  );
}

export default TopbarM;
