import React, { useState } from 'react';
import './style.css';
import { DESIGN_CONFIG } from 'utils';

function UserCampaignBottomSlider({ onChange, inputValue }) {
  const [value, setValue] = useState(inputValue);

  const updateValues = (e) => {
    const newValue = parseFloat(e.target.value);

    onChange(newValue);

    setValue(newValue);
    const userCampaignProgressLeft = document.getElementById(
      'user-campaign-bottom__progress-left'
    );

    const userCampaignProgressRight = document.getElementById(
      'user-campaign-bottom__progress-right'
    );

    const percentValue = (newValue / 5) * 100;

    userCampaignProgressLeft.style.width = `${percentValue}%`;

    userCampaignProgressRight.style.width = `${100 - percentValue}%`;
    if (newValue >= 0 && newValue < 2) {
      userCampaignProgressLeft.style.background = `${DESIGN_CONFIG.color.error.errorMain}`;

      userCampaignProgressRight.style.background = `${DESIGN_CONFIG.color.error.error50}`;

      document.documentElement.style.setProperty(
        '--thumb-border-color_user-campaign-bottom',
        `${DESIGN_CONFIG.color.error.errorMain}`
      );
    } else if (newValue >= 2 && newValue < 3) {
      userCampaignProgressLeft.style.background = `${DESIGN_CONFIG.color.warning.warningMain}`;

      userCampaignProgressRight.style.background = `${DESIGN_CONFIG.color.warning.warning50}`;

      document.documentElement.style.setProperty(
        '--thumb-border-color_user-campaign-bottom',
        `${DESIGN_CONFIG.color.warning.warningMain}`
      );
    } else if (newValue >= 3 && newValue < 4) {
      userCampaignProgressLeft.style.background = `${DESIGN_CONFIG.color.success.successMain}`;

      userCampaignProgressRight.style.background = `${DESIGN_CONFIG.color.success.success50}`;

      document.documentElement.style.setProperty(
        '--thumb-border-color_user-campaign-bottom',
        `${DESIGN_CONFIG.color.success.successMain}`
      );
    } else if (newValue >= 4 && newValue < 5) {
      userCampaignProgressLeft.style.background = `${DESIGN_CONFIG.color.primary.primaryMain}`;

      userCampaignProgressRight.style.background = `${DESIGN_CONFIG.color.primary.primary50}`;

      document.documentElement.style.setProperty(
        '--thumb-border-color_user-campaign-bottom',
        `${DESIGN_CONFIG.color.primary.primaryMain}`
      );
    } else if (newValue === 5) {
      userCampaignProgressLeft.style.background = `${DESIGN_CONFIG.color.gradient.highlight}`;

      userCampaignProgressRight.style.background = `${DESIGN_CONFIG.color.gradient.highlight}`;

      document.documentElement.style.setProperty(
        '--thumb-border-color_user-campaign',
        `${DESIGN_CONFIG.color.primary.primaryMain}`
      );
    }
  };

  return (
    <div className='user-campaign-bottom'>
      <div className='user-campaign-bottom__slider'>
        <div id='user-campaign-bottom__progress-left' />
        <div id='user-campaign-bottom__progress-right' />
      </div>
      <div className='user-campaign-bottom__range-input'>
        <input
          aria-valuemax='5'
          aria-valuemin='0'
          aria-valuenow={value}
          id='sliderInput'
          max='5'
          min='0'
          onInput={updateValues}
          step='0.1'
          type='range'
          value={value}
        />
      </div>
    </div>
  );
}

export default UserCampaignBottomSlider;
