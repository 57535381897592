/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import Box from 'ReusableComponents/Box';
import rdLogo from 'assets/Logos/rdLogo.svg';
import Text from 'ReusableComponents/Text';
import Signup from 'assets/Signup.svg';
import Button from 'ReusableComponents/Button';
import { useDispatch, useSelector } from 'react-redux';
import { activeIndex } from 'store/auth/actions';
import { useMedia } from 'helpers';
import Hamburger from 'assets/Hamburger.svg';
import Sidebar from 'Pages/Home/Components/Sidebar';
import { DESIGN_CONFIG, STRING_CONFIG } from 'utils';
import LogoSmall from '../../../assets/LogoSmall.svg';
import UserDisplayPicture from './UserDisplayPicture';
import SearchInput from './SearchInput';

function TopBar() {
  const history = useHistory();

  const menuRef = useRef(null);

  const dispatch = useDispatch();

  const authToken = useSelector(({ auth }) => auth.token);

  const index = useSelector(({ auth }) => auth.activeIndex);

  const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowHamburgerMenu(false);
      }
    };

    if (showHamburgerMenu) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [showHamburgerMenu]);

  useEffect(() => {
    if (history.location.pathname === '/home') dispatch(activeIndex(1));
    else if (history.location.pathname === '/browse') dispatch(activeIndex(2));
    else if (history.location.pathname === '/articles')
      dispatch(activeIndex(3));
    else {
      dispatch(activeIndex(-1));
    }
  }, [history]);

  const handleChangeMenu = (i) => {
    dispatch(activeIndex(i));
  };

  const smallScreen = useMedia('(max-width:1000px)');

  const MQ730 = useMedia('(max-width:730px)');

  const MQ640 = useMedia('(max-width:640px)');

  const MQ520 = useMedia('(max-width:520px)');

  return (
    <Box bb={`1px solid ${DESIGN_CONFIG.color.neutral.neutral100}`}>
      <nav className='navigation-bar'>
        <Box
          style={{
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <Link to='/'>
            <img alt='Reviewdale' height={32} src={rdLogo} width={32} />
          </Link>
          {!smallScreen && (
            <Box alignItems='center' display='flex' marginLeft='3.62rem'>
              <Box
                onClick={() => {
                  handleChangeMenu(1);
                }}
                padding='.88rem .75rem'
              >
                <Link
                  style={{
                    color: `${index === 1 ? DESIGN_CONFIG.color.primary.primaryMain : DESIGN_CONFIG.color.text.text200}`,
                    fontSize: `${DESIGN_CONFIG.typography.common.webLink.fontSize}`,
                    fontWeight: `${index === 1 ? DESIGN_CONFIG.typography.common.webActiveLink.fontWeight : DESIGN_CONFIG.typography.common.webLink.fontWeight}`,
                  }}
                  to='/home'
                >
                  {STRING_CONFIG.inPage.platform.lnkNavBarHome}
                </Link>
              </Box>
              <Box
                onClick={() => {
                  handleChangeMenu(2);
                }}
                padding='.88rem .75rem'
              >
                <Link
                  style={{
                    color: `${index === 2 ? DESIGN_CONFIG.color.primary.primaryMain : DESIGN_CONFIG.color.text.text200}`,
                    fontSize: `${DESIGN_CONFIG.typography.common.webLink.fontSize}`,
                    fontWeight: `${index === 2 ? DESIGN_CONFIG.typography.common.webActiveLink.fontWeight : DESIGN_CONFIG.typography.common.webLink.fontWeight}`,
                  }}
                  to='/browse'
                >
                  {STRING_CONFIG.inPage.platform.lnkNavBarBrowse}
                </Link>
              </Box>
              <Box
                onClick={() => {
                  handleChangeMenu(2);
                }}
                padding='.88rem .75rem'
              >
                <Link
                  style={{
                    color: `${index === 3 ? DESIGN_CONFIG.color.primary.primaryMain : DESIGN_CONFIG.color.text.text200}`,
                    fontSize: `${DESIGN_CONFIG.typography.common.webLink.fontSize}`,
                    fontWeight: `${index === 3 ? DESIGN_CONFIG.typography.common.webActiveLink.fontWeight : DESIGN_CONFIG.typography.common.webLink.fontWeight}`,
                  }}
                  to='/articles'
                >
                  {STRING_CONFIG.inPage.platform.lnkNavBarArticle}
                </Link>
              </Box>
            </Box>
          )}
        </Box>
        <Box alignItems='center' display='flex' position='relative'>
          <SearchInput />
          <Box
            alignItems='center'
            display='flex'
            marginLeft={MQ640 ? '.5rem' : '1.25rem'}
          >
            {authToken ? (
              <Box display='flex'>
                <UserDisplayPicture />
              </Box>
            ) : (
              <Button
                aria-label='Sign In'
                alignItems='center'
                background={DESIGN_CONFIG.color.neutral.neutral500}
                border={`1px solid ${DESIGN_CONFIG.color.primary.primaryMain}`}
                borderRadius='6px'
                cursor='pointer'
                display='flex'
                h={MQ640 ? '2.25rem' : MQ730 ? '2.5rem' : '3rem'}
                justifyContent='center'
                m='2px auto'
                onClick={() => history.push('/sign-in')}
                w={MQ640 ? '6.25rem' : MQ730 ? '7rem' : '8.75rem'}
              >
                <img
                  alt=''
                  aria-hidden
                  height={MQ640 && '0.938rem'}
                  src={Signup}
                  style={{ marginRight: `${MQ520 ? '0.375rem' : '0.625rem'}` }}
                  width={MQ640 && '0.938rem'}
                />
                <Text
                  fontSize={
                    MQ520
                      ? '0.813rem'
                      : `${DESIGN_CONFIG.typography.common.webButton.fontSize}`
                  }
                  fontWeight={
                    DESIGN_CONFIG.typography.common.webButton.fontWeight
                  }
                  marginBottom='0'
                  textColor={DESIGN_CONFIG.color.primary.primaryMain}
                >
                  {STRING_CONFIG.inPage.common.txtSignIn}
                </Text>
              </Button>
            )}
            {smallScreen && (
              <button
                className='hamburger-button'
                cursor='pointer'
                onClick={() => setShowHamburgerMenu(!showHamburgerMenu)}
                type='button'
              >
                <img
                  alt='hamburger'
                  aria-hidden
                  height='1.875rem'
                  src={Hamburger}
                  width='1.875rem'
                />
              </button>
            )}
          </Box>
        </Box>
        <div
          aria-hidden={!smallScreen}
          className={`overlay ${showHamburgerMenu ? 'expanded' : ''}`}
        >
          <nav
            aria-label='main menu'
            className={`hamburger-menu ${showHamburgerMenu ? 'expanded' : ''}`}
            ref={menuRef}
          >
            <Box
              aria-hidden
              display='flex'
              justifyContent='center'
              margin='24px 0'
            >
              <img alt='' height='3rem' src={LogoSmall} />
            </Box>
            <Sidebar />
          </nav>
        </div>
      </nav>
    </Box>
  );
}

export default TopBar;
