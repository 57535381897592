import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  showSuccess,
  updateAdTrayStatus,
  updateTransaction,
} from 'store/auth/actions';
import { CircularProgress } from '@material-ui/core';
import Up from 'assets/Up.svg';
import Down from 'assets/Down.svg';
import Ad from 'assets/images/Ad.svg';
import Button from 'ReusableComponents/Button';
import Box from 'ReusableComponents/Box';
import Text from 'ReusableComponents/Text';
import { getAdsDurationString, useMedia } from 'helpers';
import { getTransactions, initiateTransaction } from 'api/platformRequest';
import { DESIGN_CONFIG, STRING_CONFIG } from 'utils';
import RangeInput from './RangeInput';
import './style.css';

function StatusComponent({
  transactionAmount,
  setTransactionAmount,
  time,
  handleOpen,
  open,
  closeTime,
  seconds,
}) {
  const amount = useSelector((state) => state.auth.adTray.amount);

  const authToken = useSelector((state) => state.auth.token);

  const dispatch = useDispatch();

  const isSmall = useMedia('(max-width:450px)');

  const status = useSelector((state) => state.auth.adTray.status);

  const success = useSelector((state) => state.auth.adTray.showSuccess);

  const currency = 'INR';

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement('script');

      script.src = src;

      script.onload = () => {
        resolve(true);
      };

      script.onerror = () => {
        resolve(false);
      };

      document.body.appendChild(script);
    });
  }

  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.error('Error: AdsbyGoogle with:', e);
    }
  }, []);

  const initPayment = (paymentData) => {
    const options = {
      amount: paymentData.amount,
      currency: paymentData.currency,
      description: 'Hide Ad Tray',
      handler: async () => {
        setTimeout(async () => {
          const { data } = await getTransactions(authToken);
          if (data.success) {
            dispatch(showSuccess(true));

            dispatch(updateTransaction(data.transactions));
          }
        }, 3000);
      },
      image: '',
      key: process.env.REACT_APP_RAZORPAY_KEY,
      name: 'ReviewDale',
      order_id: paymentData.id,
      theme: {
        color: DESIGN_CONFIG.color.primary.primaryMain,
      },
    };

    const rzp1 = new window.Razorpay(options);

    rzp1.open();
  };

  const CreateOrder = async () => {
    const payload = {
      transactionCurrency: currency,
      transactionAmount: String(transactionAmount),
    };

    const { data } = await initiateTransaction(payload, authToken);
    if (data.success) {
      initPayment(data.gateway_order_details);
    }
  };

  async function displayPaymentGateway() {
    const res = await loadScript(
      'https://checkout.razorpay.com/v1/checkout.js'
    );
    if (!res) {
      alert('Payment Gateway failed to load...');
    } else {
      CreateOrder();
    }
  }

  switch (status) {
    case 1: // Transaction canceled
      return (
        <Box
          background={DESIGN_CONFIG.color.neutral.neutral500}
          boxShadow={DESIGN_CONFIG.color.common.boxShadow}
          bottom={0}
          h='auto'
          left='50%'
          padding={isSmall ? '1rem' : '1rem 1.5rem'}
          position='fixed'
          transform='translateX(-50%)'
          w={isSmall ? '375px' : '776px'}
          zIndex='12'
        >
          {!open ? (
            <div
              className='progress-bar'
              style={{ width: `${(seconds / 30) * 100}%` }}
            />
          ) : null}
          <Box
            alignItems='center'
            display='flex'
            justifyContent='space-between'
            position='relative'
            w='100%'
          >
            {!open && (
              <Text
                fontSize={DESIGN_CONFIG.typography.common.webLabel.fontSize}
                fontWeight={DESIGN_CONFIG.typography.common.webLabel.fontWeight}
                marginBottom={0}
                marginRight='0.625rem'
                textColor={DESIGN_CONFIG.color.text.text100}
              >
                {STRING_CONFIG.inPage.adTray.txtNextAdIn} {time()}
              </Text>
            )}
            {open && closeTime ? (
              <CircularProgress
                style={{
                  color: `${DESIGN_CONFIG.color.primary.primaryMain}`,
                  height: 16,
                  marginBottom: 10,
                  marginLeft: 'auto',
                  position: `${isSmall && open ? 'absolute' : ''}`,
                  right: `${isSmall && open ? '50%' : '0'}`,
                  width: 16,
                }}
              />
            ) : (
              <img
                alt='open'
                onClick={handleOpen}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    handleOpen();
                  }
                }}
                role='button' // eslint-disable-line jsx-a11y/no-noninteractive-element-to-interactive-role
                src={open ? Down : Up}
                style={{
                  cursor: 'pointer',
                  marginBottom: 8,
                  marginLeft: `${isSmall ? '.5rem' : 'auto'}`,
                  position: `${isSmall && open ? 'absolute' : ''}`,
                  right: `${isSmall && open ? '50%' : '0'}`,
                }}
              />
            )}
          </Box>
          {open && (
            <Box
              className='animate__animated animate__slideInUp'
              style={{ transition: 'all 1s ease-out', marginTop: '.3rem' }}
            >
              <Box
                display='flex'
                h={isSmall ? '170px' : '90px'}
                justifyContent='center'
                w='100%'
              >
                <ins
                  class='adsbygoogle'
                  style={{ display: 'block', width: '100%', height: '100%' }}
                  data-ad-client='ca-pub-6460111194711544'
                  data-ad-slot='6953943666'
                  data-ad-format='auto'
                  data-full-width-responsive='true'
                ></ins>
              </Box>
              <Box
                b={`1px solid ${DESIGN_CONFIG.color.neutral.neutral100}`}
                borderRadius='6px'
                marginTop={isSmall ? '.75rem' : '1rem'}
                padding={isSmall ? '.75rem' : '1rem'}
              >
                <Box>
                  <Text
                    fontSize={
                      isSmall
                        ? '1.25rem'
                        : `${DESIGN_CONFIG.typography.mainCard.webMnCardTitle.fontSize}`
                    }
                    fontWeight={
                      DESIGN_CONFIG.typography.mainCard.webMnCardTitle
                        .fontWeight
                    }
                    marginBottom={isSmall ? '.5rem' : '0.5rem'}
                  >
                    {STRING_CONFIG.inPage.adTray.txtPaymentCancelFirst}
                  </Text>
                </Box>
                <Box w='100%'>
                  <Text
                    fontSize={
                      isSmall
                        ? '1rem'
                        : `${DESIGN_CONFIG.typography.mainCard.webMnCardSubtitle.fontSize}`
                    }
                    fontWeight={
                      DESIGN_CONFIG.typography.mainCard.webMnCardSubtitle
                        .fontWeight
                    }
                    lineHeight={isSmall ? '1.375rem' : ''}
                    marginBottom='.75rem'
                    textColor={DESIGN_CONFIG.color.text.text100}
                  >
                    {STRING_CONFIG.inPage.adTray.txtPaymentCancelSecond}
                  </Text>
                </Box>
                <Box
                  display='flex'
                  flexDirection={isSmall ? 'column' : ''}
                  gap={isSmall ? '1rem' : '1.5rem'}
                >
                  <Box
                    alignItems='center'
                    display={isSmall ? 'none' : 'flex'}
                    flexDirection='column'
                    justifyContent='center'
                    h='7.75rem'
                    maxW='15.5rem'
                  >
                    <ins
                      class='adsbygoogle'
                      style={{
                        display: 'block',
                        height: '100%',
                        width: '100%',
                      }}
                      data-ad-client='ca-pub-6460111194711544'
                      data-ad-slot='6953943666'
                      data-ad-format='auto'
                      data-full-width-responsive='true'
                    ></ins>
                    <Text
                      fontSize={
                        DESIGN_CONFIG.typography.mainCard.webMnCardTitle
                          .fontSize
                      }
                      fontWeight={
                        DESIGN_CONFIG.typography.mainCard.webMnCardTitle
                          .fontWeight
                      }
                      lineHeight={
                        DESIGN_CONFIG.typography.mainCard.webMnCardTitle
                          .lineHeight
                      }
                      margin='0.625rem 0 0 0'
                      textAlign='center'
                      textColor={DESIGN_CONFIG.color.text.text500}
                    >
                      250+ people around you went Ad-free this week
                    </Text>
                  </Box>
                  <Box alignSelf='center' flex={1} w={isSmall ? '100%' : ''}>
                    <Box>
                      <img
                        style={{
                          left: '10px',
                          position: 'relative',
                          width: '100%',
                        }}
                        src='/assets/adTrayPrice.svg'
                        alt='price tray'
                      />
                    </Box>
                    <RangeInput setTransactionAmount={setTransactionAmount} />
                    <Button
                      border='none'
                      background={DESIGN_CONFIG.color.primary.primaryMain}
                      borderRadius='6px'
                      cursor='pointer'
                      h='44px'
                      marginTop='1.25rem'
                      onClick={() => dispatch(updateAdTrayStatus(null))}
                      w='100%'
                    >
                      <Text
                        fontSize={
                          DESIGN_CONFIG.typography.common.webButton.fontSize
                        }
                        fontWeight={
                          DESIGN_CONFIG.typography.common.webButton.fontWeight
                        }
                        margin={0}
                        textColor={DESIGN_CONFIG.color.neutral.neutral500}
                      >
                        {`${getAdsDurationString(transactionAmount)}`}
                      </Text>
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      );
    case 2: // Transaction pending
      return (
        <Box
          background={DESIGN_CONFIG.color.neutral.neutral500}
          boxShadow={DESIGN_CONFIG.color.common.boxShadow}
          bottom={0}
          h='auto'
          left='50%'
          padding={isSmall ? '1rem' : '1rem 1.5rem'}
          position='fixed'
          transform='translateX(-50%)'
          w={isSmall ? '375px' : '776px'}
          zIndex='12'
        >
          {!open ? (
            <div
              className='progress-bar'
              style={{ width: `${(seconds / 30) * 100}%` }}
            />
          ) : null}
          <Box
            alignItems='center'
            display='flex'
            justifyContent='space-between'
            position='relative'
            w='100%'
          >
            {!open && (
              <Text
                fontSize={DESIGN_CONFIG.typography.common.webLabel.fontSize}
                fontWeight={DESIGN_CONFIG.typography.common.webLabel.fontWeight}
                marginBottom={0}
                marginRight='0.625rem'
                textColor={DESIGN_CONFIG.color.text.text100}
              >
                {STRING_CONFIG.inPage.adTray.txtNextAdIn} {time()}
              </Text>
            )}
            {open && closeTime ? (
              <CircularProgress
                style={{
                  color: `${DESIGN_CONFIG.color.primary.primaryMain}`,
                  height: 16,
                  marginBottom: 10,
                  marginLeft: 'auto',
                  position: `${isSmall && open ? 'absolute' : ''}`,
                  right: `${isSmall && open ? '50%' : '0'}`,
                  width: 16,
                }}
              />
            ) : (
              <img
                alt='Toggle open/close'
                onClick={handleOpen}
                role='button' // eslint-disable-line jsx-a11y/no-noninteractive-element-to-interactive-role
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    handleOpen();
                  }
                }}
                src={open ? Down : Up}
                style={{
                  cursor: 'pointer',
                  marginBottom: 8,
                  marginLeft: `${isSmall ? '.5rem' : 'auto'}`,
                  position: `${isSmall && open ? 'absolute' : ''}`,
                  right: `${isSmall && open ? '50%' : '0'}`,
                }}
              />
            )}
          </Box>
          {open && (
            <Box
              className='animate__animated animate__slideInUp'
              style={{ transition: 'all 1s ease-out', marginTop: '.3rem' }}
            >
              <Box
                display='flex'
                h={isSmall ? '170px' : '90px'}
                justifyContent='center'
                w='100%'
              >
                <ins
                  class='adsbygoogle'
                  style={{ display: 'block', width: '100%', height: '100%' }}
                  data-ad-client='ca-pub-6460111194711544'
                  data-ad-slot='6953943666'
                  data-ad-format='auto'
                  data-full-width-responsive='true'
                ></ins>
              </Box>
              <Box
                b={`1px solid ${DESIGN_CONFIG.color.neutral.neutral100}`}
                borderRadius='6px'
                marginTop={isSmall ? '.75rem' : '1rem'}
                padding={isSmall ? '.75rem' : '1rem'}
              >
                <Box>
                  <Text
                    fontSize={
                      isSmall
                        ? '1.25rem'
                        : `${DESIGN_CONFIG.typography.mainCard.webMnCardTitle.fontSize}`
                    }
                    fontWeight={
                      DESIGN_CONFIG.typography.mainCard.webMnCardTitle
                        .fontWeight
                    }
                    marginBottom={isSmall ? '.5rem' : '.75rem'}
                    textColor={DESIGN_CONFIG.color.warning.warning900}
                  >
                    {STRING_CONFIG.inPage.adTray.txtPaymentPendingFirst}
                  </Text>
                  <Text
                    fontSize={
                      isSmall
                        ? '1rem'
                        : `${DESIGN_CONFIG.typography.mainCard.webMnCardSubtitle.fontSize}`
                    }
                    fontWeight={
                      DESIGN_CONFIG.typography.mainCard.webMnCardSubtitle
                        .fontWeight
                    }
                    marginBottom={isSmall ? '.75rem' : '1rem'}
                    textColor={DESIGN_CONFIG.color.warning.warning900}
                  >
                    {STRING_CONFIG.inPage.adTray.txtPaymentPendingSecond}
                  </Text>
                  <Button
                    border='none'
                    borderRadius='6px'
                    background={DESIGN_CONFIG.color.primary.primaryMain}
                    fontSize={
                      DESIGN_CONFIG.typography.common.webButton.fontSize
                    }
                    fontWeight={
                      DESIGN_CONFIG.typography.common.webButton.fontWeight
                    }
                    h='44px'
                    onClick={() => dispatch(updateAdTrayStatus(null))}
                    padding='.75rem'
                    textColor={DESIGN_CONFIG.color.neutral.neutral500}
                    w='100%'
                  >
                    {STRING_CONFIG.inPage.common.btnOk}
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      );
    case 3: // Transaction success
      return (
        <>
          {success ? (
            <Box
              background={DESIGN_CONFIG.color.neutral.neutral500}
              b={`2px solid ${DESIGN_CONFIG.color.neutral.neutral100}`}
              boxShadow={DESIGN_CONFIG.color.common.boxShadow}
              bottom={0}
              h='auto'
              left='50%'
              position='fixed'
              padding='1rem'
              transform='translateX(-50%)'
              w={isSmall ? '375px' : '454px'}
              zIndex='12'
            >
              <Box
                alignItems='center'
                display='flex'
                gap='.5rem'
                marginBottom='1rem'
              >
                <Box
                  background={DESIGN_CONFIG.color.primary.primaryMain}
                  borderRadius='4px'
                  color={DESIGN_CONFIG.color.neutral.neutral500}
                  height='fit-content'
                  maxW='60px'
                  padding='1rem .5rem'
                >
                  {getAdsDurationString(amount)}
                </Box>
                <Box>
                  <Text
                    fontSize={
                      isSmall
                        ? '1.25rem'
                        : `${DESIGN_CONFIG.typography.mainCard.webMnCardTitle.fontSize}`
                    }
                    fontWeight={
                      DESIGN_CONFIG.typography.mainCard.webMnCardTitle
                        .fontWeight
                    }
                    lineHeight={
                      DESIGN_CONFIG.typography.mainCard.webMnCardTitle
                        .lineHeight
                    }
                    margin='0'
                    textColor={DESIGN_CONFIG.color.text.text200}
                  >
                    {STRING_CONFIG.inPage.adTray.txtPaymentSuccessFirst}
                  </Text>
                  <Text
                    fontSize={
                      isSmall
                        ? '1rem'
                        : `${DESIGN_CONFIG.typography.mainCard.webMnCardSubtitle.fontSize}`
                    }
                    fontWeight={
                      DESIGN_CONFIG.typography.mainCard.mobMnCardSubtitle
                        .fontWeight
                    }
                    lineHeight={
                      DESIGN_CONFIG.typography.mainCard.mobMnCardSubtitle
                        .lineHeight
                    }
                    margin='0'
                    textColor={DESIGN_CONFIG.color.text.text200}
                  >
                    {STRING_CONFIG.inPage.adTray.txtPaymentSuccessSecond}
                  </Text>
                </Box>
              </Box>
              <Button
                border='none'
                borderRadius='6px'
                background={DESIGN_CONFIG.color.primary.primaryMain}
                fontSize={DESIGN_CONFIG.typography.common.webButton.fontSize}
                fontWeight={
                  DESIGN_CONFIG.typography.common.webButton.fontWeight
                }
                h='44px'
                marginTop={isSmall ? '1rem' : ''}
                onClick={() => dispatch(showSuccess(false))}
                padding='.75rem'
                textColor={DESIGN_CONFIG.color.neutral.neutral500}
                w={isSmall ? '100%' : '-webkit-fill-available'}
              >
                {STRING_CONFIG.inPage.adTray.btnStartAdFreeCTA}
              </Button>
            </Box>
          ) : null}
        </>
      );
    case 4: // Transaction failed
      return (
        <Box
          background={DESIGN_CONFIG.color.neutral.neutral500}
          boxShadow={DESIGN_CONFIG.color.common.boxShadow}
          bottom={0}
          h='auto'
          left='50%'
          padding={isSmall ? '1rem' : '1rem 1.5rem'}
          position='fixed'
          transform='translateX(-50%)'
          w={isSmall ? '375px' : '776px'}
          zIndex='12'
        >
          {!open ? (
            <div
              className='progress-bar'
              style={{ width: `${(seconds / 30) * 100}%` }}
            />
          ) : null}
          <Box
            alignItems='center'
            display='flex'
            justifyContent='space-between'
            position='relative'
            w='100%'
          >
            {!open && (
              <Text
                fontSize={DESIGN_CONFIG.typography.common.webLabel.fontSize}
                fontWeight={DESIGN_CONFIG.typography.common.webLabel.fontWeight}
                marginBottom={0}
                marginRight='0.625rem'
                textColor={DESIGN_CONFIG.color.text.text100}
              >
                {STRING_CONFIG.inPage.adTray.txtNextAdIn} {time()}
              </Text>
            )}
            {open && closeTime ? (
              <CircularProgress
                style={{
                  color: `${DESIGN_CONFIG.color.primary.primaryMain}`,
                  height: 16,
                  marginBottom: 10,
                  marginLeft: 'auto',
                  position: `${isSmall && open ? 'absolute' : ''}`,
                  right: `${isSmall && open ? '50%' : '0'}`,
                  width: 16,
                }}
              />
            ) : (
              <img
                alt='open'
                onClick={handleOpen}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    handleOpen();
                  }
                }}
                src={open ? Down : Up}
                role='button' // eslint-disable-line jsx-a11y/no-noninteractive-element-to-interactive-role
                style={{
                  cursor: 'pointer',
                  marginBottom: 8,
                  marginLeft: `${isSmall ? '.5rem' : 'auto'}`,
                  position: `${isSmall && open ? 'absolute' : ''}`,
                  right: `${isSmall && open ? '50%' : '0'}`,
                }}
              />
            )}
          </Box>
          {open && (
            <Box
              className='animate__animated animate__slideInUp'
              style={{ transition: 'all 1s ease-out', marginTop: '.3rem' }}
            >
              <Box
                display='flex'
                h={isSmall ? '170px' : '90px'}
                justifyContent='center'
                w='100%'
              >
                <ins
                  class='adsbygoogle'
                  style={{ display: 'block', width: '100%', height: '100%' }}
                  data-ad-client='ca-pub-6460111194711544'
                  data-ad-slot='6953943666'
                  data-ad-format='auto'
                  data-full-width-responsive='true'
                ></ins>
              </Box>
              <Box
                b={`1px solid ${DESIGN_CONFIG.color.neutral.neutral100}`}
                borderRadius='6px'
                marginTop={isSmall ? '.75rem' : '1rem'}
                padding={isSmall ? '.75rem' : '1rem'}
              >
                <Box>
                  <Text
                    fontSize={
                      isSmall
                        ? '1.25rem'
                        : `${DESIGN_CONFIG.typography.mainCard.webMnCardTitle.fontSize}`
                    }
                    fontWeight={
                      DESIGN_CONFIG.typography.mainCard.webMnCardTitle
                        .fontWeight
                    }
                    marginBottom={isSmall ? '.5rem' : '.75rem'}
                    textColor={DESIGN_CONFIG.color.error.errorMain}
                  >
                    {STRING_CONFIG.inPage.adTray.txtPaymentFailFirst}
                  </Text>
                  <Text
                    fontSize={
                      isSmall
                        ? '1rem'
                        : `${DESIGN_CONFIG.typography.mainCard.webMnCardSubtitle.fontSize}`
                    }
                    fontWeight={
                      DESIGN_CONFIG.typography.mainCard.webMnCardSubtitle
                        .fontWeight
                    }
                    lineHeight={isSmall ? '1.375rem' : ''}
                    marginBottom={isSmall ? '.75rem' : '1rem'}
                    textColor={DESIGN_CONFIG.color.error.errorMain}
                  >
                    {STRING_CONFIG.inPage.adTray.txtPaymentFailSecond}
                  </Text>
                  <Button
                    border='none'
                    borderRadius='6px'
                    background={DESIGN_CONFIG.color.primary.primaryMain}
                    fontSize={
                      DESIGN_CONFIG.typography.common.webButton.fontSize
                    }
                    fontWeight={
                      DESIGN_CONFIG.typography.common.webButton.fontWeight
                    }
                    h='44px'
                    onClick={() => dispatch(updateAdTrayStatus(null))}
                    padding='.75rem'
                    textColor={DESIGN_CONFIG.color.neutral.neutral500}
                    w='100%'
                  >
                    {STRING_CONFIG.inPage.adTray.btnRetryPurchase}
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      );
    case null: // default
      return (
        <Box
          background={DESIGN_CONFIG.color.neutral.neutral500}
          boxShadow={DESIGN_CONFIG.color.common.boxShadow}
          bottom={0}
          h='auto'
          left='50%'
          padding={isSmall ? '1rem' : '1rem 1.5rem'}
          position='fixed'
          transform='translateX(-50%)'
          w={isSmall ? '375px' : '776px'}
          zIndex='12'
        >
          {!open ? (
            <div
              className='progress-bar'
              style={{ width: `${(seconds / 30) * 100}%` }}
            />
          ) : null}
          <Box
            alignItems='center'
            display='flex'
            justifyContent='space-between'
            position='relative'
            w='100%'
          >
            {!open && (
              <Text
                fontSize={DESIGN_CONFIG.typography.common.webLabel.fontSize}
                fontWeight={DESIGN_CONFIG.typography.common.webLabel.fontWeight}
                marginBottom={0}
                marginRight='0.625rem'
                textColor={DESIGN_CONFIG.color.text.text100}
              >
                {STRING_CONFIG.inPage.adTray.txtNextAdIn} {time()}
              </Text>
            )}
            {open && closeTime ? (
              <CircularProgress
                style={{
                  color: `${DESIGN_CONFIG.color.primary.primaryMain}`,
                  height: 16,
                  marginBottom: 10,
                  marginLeft: 'auto',
                  position: `${isSmall && open ? 'absolute' : ''}`,
                  right: `${isSmall && open ? '50%' : '0'}`,
                  width: 16,
                }}
              />
            ) : (
              <img
                alt='open'
                onClick={handleOpen}
                src={open ? Down : Up}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    handleOpen();
                  }
                }}
                role='button' // eslint-disable-line jsx-a11y/no-noninteractive-element-to-interactive-role
                style={{
                  cursor: 'pointer',
                  marginBottom: 8,
                  marginLeft: `${isSmall ? '.5rem' : 'auto'}`,
                  position: `${isSmall && open ? 'absolute' : ''}`,
                  right: `${isSmall && open ? '50%' : '0'}`,
                }}
              />
            )}
          </Box>
          {open && (
            <Box
              className='animate__animated animate__slideInUp'
              style={{ transition: 'all 1s ease-out', marginTop: '.3rem' }}
            >
              <Box
                display='flex'
                h={isSmall ? '170px' : '90px'}
                justifyContent='center'
                w='100%'
              >
                <ins
                  class='adsbygoogle'
                  style={{ display: 'block', width: '100%', height: '100%' }}
                  data-ad-client='ca-pub-6460111194711544'
                  data-ad-slot='6953943666'
                  data-ad-format='auto'
                  data-full-width-responsive='true'
                ></ins>
              </Box>
              <Box
                b={`1px solid ${DESIGN_CONFIG.color.neutral.neutral100}`}
                borderRadius='6px'
                marginTop={isSmall ? '.75rem' : '1rem'}
                padding={isSmall ? '.75rem' : '1rem'}
              >
                <Box>
                  <Text
                    fontSize={
                      isSmall
                        ? '1.25rem'
                        : `${DESIGN_CONFIG.typography.mainCard.webMnCardTitle.fontSize}`
                    }
                    fontWeight={
                      DESIGN_CONFIG.typography.mainCard.webMnCardTitle
                        .fontWeight
                    }
                    marginBottom={isSmall ? '.5rem' : '0.5rem'}
                  >
                    {STRING_CONFIG.inPage.adTray.txtPageTitle}
                  </Text>
                </Box>
                <Box w='100%'>
                  <Text
                    fontSize={
                      isSmall
                        ? '1rem'
                        : `${DESIGN_CONFIG.typography.mainCard.webMnCardSubtitle.fontSize}`
                    }
                    fontWeight={
                      DESIGN_CONFIG.typography.mainCard.webMnCardSubtitle
                        .fontWeight
                    }
                    lineHeight={isSmall ? '1.375rem' : ''}
                    marginBottom='.75rem'
                    textColor={DESIGN_CONFIG.color.text.text100}
                  >
                    {STRING_CONFIG.inPage.adTray.txtPageSubtext}
                  </Text>
                </Box>
                <Box
                  display='flex'
                  flexDirection={isSmall ? 'column' : ''}
                  gap={isSmall ? '1rem' : '1.5rem'}
                >
                  <Box
                    alignItems='center'
                    display={isSmall ? 'none' : 'flex'}
                    flexDirection='column'
                    justifyContent='center'
                    h='7.75rem'
                    maxW='15.5rem'
                  >
                    <img
                      alt='250+ User Donated Recently'
                      height={40}
                      src='/assets/adTrayUserGroup.svg'
                      width={232}
                    />
                    <Text
                      fontSize={
                        DESIGN_CONFIG.typography.mainCard.webMnCardTitle
                          .fontSize
                      }
                      fontWeight={
                        DESIGN_CONFIG.typography.mainCard.webMnCardTitle
                          .fontWeight
                      }
                      lineHeight={
                        DESIGN_CONFIG.typography.mainCard.webMnCardTitle
                          .lineHeight
                      }
                      margin='0.625rem 0 0 0'
                      textAlign='center'
                      textColor={DESIGN_CONFIG.color.text.text500}
                    >
                      250+ people around you went Ad-free this week
                    </Text>
                  </Box>
                  <Box alignSelf='center' flex={1} w={isSmall ? '100%' : ''}>
                    <Box>
                      <img
                        style={{
                          left: '10px',
                          position: 'relative',
                          width: '100%',
                        }}
                        src='/assets/adTrayPrice.svg'
                        alt='price tray'
                      />
                    </Box>
                    <RangeInput setTransactionAmount={setTransactionAmount} />
                    <Button
                      border='none'
                      background={DESIGN_CONFIG.color.primary.primaryMain}
                      borderRadius='6px'
                      cursor='pointer'
                      h='44px'
                      marginTop='1.25rem'
                      onClick={() => {
                        displayPaymentGateway();
                      }}
                      w='100%'
                    >
                      <Text
                        fontSize={
                          DESIGN_CONFIG.typography.common.webButton.fontSize
                        }
                        fontWeight={
                          DESIGN_CONFIG.typography.common.webButton.fontWeight
                        }
                        margin={0}
                        textColor={DESIGN_CONFIG.color.neutral.neutral500}
                      >
                        {`${STRING_CONFIG.inPage.platform.btnHideAdsFor} ${getAdsDurationString(transactionAmount)}`}
                      </Text>
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      );
    default:
  }
}

export default StatusComponent;
