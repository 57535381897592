import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
// import ReactGA from 'react-ga4';
import NotFound404 from 'assets/NotFound404.svg';
import internalServerError from 'assets/internalServerError.svg';
import { CircularProgress } from '@mui/material';
import UserCampaign from 'Pages/InProgress';
import MSearch from 'Pages/MSearch';
import { STRING_CONFIG } from 'utils';
import DeleteMyAccount from 'Pages/DeleteMyAccount';
import { useAdTray } from 'contexts/AdTrayContext';
import AdTray from 'Pages/Components/AdTray';
import Toast from 'ReusableComponents/Toast';
import PrivateRoute from './PrivateRoute';
import RestrictedRoute from './RestrictedRoute';

const About = lazy(() => import('Pages/About'));

const Articles = lazy(() => import('Pages/Articles'));

const Browse = lazy(() => import('Pages/Browse'));

const CommunityGuidelines = lazy(() => import('Pages/CommunityGuidelines'));

const Corporate = lazy(() => import('Pages/Corporate'));

const EmailVerificationCheckEmail = lazy(
  () => import('Pages/EmailVerificationCheckEmail')
);

const ErrorPages = lazy(() => import('ReusableComponents/ErrorPages'));

const HelpCenter = lazy(() => import('Pages/HelpCenter'));

const HelpCenterTopicOne = lazy(
  () => import('Pages/HelpCenter/HelpCenterTopicOne')
);

const HelpCenterTopicTwo = lazy(
  () => import('Pages/HelpCenter/HelpCenterTopicTwo')
);

const HelpCenterTopicThree = lazy(
  () => import('Pages/HelpCenter/HelpCenterTopicThree')
);

const HelpCenterTopicFour = lazy(
  () => import('Pages/HelpCenter/HelpCenterTopicFour')
);

const HelpCenterTopicFive = lazy(
  () => import('Pages/HelpCenter/HelpCenterTopicFive')
);

const HelpCenterTopicSix = lazy(
  () => import('Pages/HelpCenter/HelpCenterTopicSix')
);

const HelpCenterTopicSeven = lazy(
  () => import('Pages/HelpCenter/HelpCenterTopicSeven')
);

const HelpCenterTopicEight = lazy(
  () => import('Pages/HelpCenter/HelpCenterTopicEight')
);

const HelpCenterTopicNine = lazy(
  () => import('Pages/HelpCenter/HelpCenterTopicNine')
);

const HelpCenterTopicTen = lazy(
  () => import('Pages/HelpCenter/HelpCenterTopicTen')
);

const HelpCenterTopicEleven = lazy(
  () => import('Pages/HelpCenter/HelpCenterTopicEleven')
);

const HelpCenterTopicTwelve = lazy(
  () => import('Pages/HelpCenter/HelpCenterTopicTwelve')
);

const HelpCenterTopicThirteen = lazy(
  () => import('Pages/HelpCenter/HelpCenterTopicThirteen')
);

const HelpCenterTopicFourteen = lazy(
  () => import('Pages/HelpCenter/HelpCenterTopicFourteen')
);

const Home = lazy(() => import('Pages/Home'));

const InitiateForgotPassword = lazy(
  () => import('Pages/InitiateForgotPassword')
);

const MyAccount = lazy(() => import('Pages/MyAccount'));

const MostUpvotedProducts = lazy(
  () => import('Pages/ProductsByCategory/MostUpvotedProducts')
);

const MostReviewedProducts = lazy(
  () => import('Pages/ProductsByCategory/MostReviewedProducts')
);

const MostViewedProducts = lazy(
  () => import('Pages/ProductsByCategory/MostViewedProducts')
);

const PlatformSearch = lazy(() => import('Pages/PlatformSearch'));

const PrivacyPolicy = lazy(() => import('Pages/PrivacyPolicy'));

const ProductCompare = lazy(() => import('Pages/ProductCompare'));

const Product = lazy(() => import('Pages/Product'));

const ReportUser = lazy(() => import('Pages/ReportUser'));

const ReviewDetails = lazy(() => import('Pages/ReviewDetails'));

const ShopTogether = lazy(() => import('Pages/ShopTogether'));

const SignUpEmailVerification = lazy(
  () => import('Pages/SignUpEmailVerification')
);

const TermsOfService = lazy(() => import('Pages/TermsOfService'));

const UserAuth = lazy(() => import('Pages/UserAuth'));

const UserProfile = lazy(() => import('Pages/UserProfile'));

const VerifyForgotPassword = lazy(() => import('Pages/VerifyForgotPassword'));

function Routes({ location }) {
  const Style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  };

  const { showAdTray } = useAdTray();

  // useEffect(() => {
  //   ReactGA.send({
  //     hitType: 'pageview',
  //     page: location.pathname + location.search,
  //   });
  // }, [location]);

  return (
    <Suspense
      fallback={
        <div style={Style}>
          <CircularProgress />
        </div>
      }
    >
      <Switch>
        {/* public routes start */}
        <Route exact path='/' component={About} />
        <Route exact path='/about' component={About} />
        <Route exact path='/articles' component={Articles} />
        <Route exact path='/browse' component={Browse} />
        <Route
          exact
          path='/community-guidelines'
          component={CommunityGuidelines}
        />
        <Route exact path='/compare' component={ProductCompare} />
        <Route exact path='/corporate' component={Corporate} />
        <Route
          exact
          path='/create-new-password/:token'
          component={VerifyForgotPassword}
        />
        <Route exact path='/delete-my-account' component={DeleteMyAccount} />
        <Route
          path='/email-verification'
          component={EmailVerificationCheckEmail}
        />
        <Route exact path='/help-center' component={HelpCenter} />
        <Route
          exact
          path='/help-getting-started-with-reviewdale'
          component={HelpCenterTopicOne}
        />
        <Route
          exact
          path='/help-browsing-and-searching-products'
          component={HelpCenterTopicTwo}
        />
        <Route
          exact
          path='/help-product-comparison-tool'
          component={HelpCenterTopicThree}
        />
        <Route
          exact
          path='/help-explore-ad-free-experience'
          component={HelpCenterTopicFour}
        />
        <Route
          exact
          path='/help-writing-and-submitting-product-review'
          component={HelpCenterTopicFive}
        />
        <Route
          exact
          path='/help-asking-questions-about-products'
          component={HelpCenterTopicSix}
        />
        <Route
          exact
          path='/help-commenting-on-review-and-discussion'
          component={HelpCenterTopicSeven}
        />
        <Route
          exact
          path='/help-answering-questions-from-community'
          component={HelpCenterTopicEight}
        />
        <Route
          exact
          path='/help-managing-reviewdale-account'
          component={HelpCenterTopicNine}
        />
        <Route
          exact
          path='/help-saving-and-organizing-favorites'
          component={HelpCenterTopicTen}
        />
        <Route
          exact
          path='/help-setting-up-alert-and-notifications'
          component={HelpCenterTopicEleven}
        />
        <Route
          exact
          path='/help-contacting-support-for-assistance'
          component={HelpCenterTopicTwelve}
        />
        <Route
          exact
          path='/help-report-bug'
          component={HelpCenterTopicThirteen}
        />
        <Route
          exact
          path='/help-new-feature-request'
          component={HelpCenterTopicFourteen}
        />
        <Route exact path='/home' component={Home} />
        <Route
          exact
          path='/homepage/:token'
          component={SignUpEmailVerification}
        />
        <Route
          exact
          path='/initiate-forgot-password'
          component={InitiateForgotPassword}
        />
        <Route
          exact
          path='/most-reviewed-products'
          component={MostReviewedProducts}
        />
        <Route
          exact
          path='/most-upvoted-products'
          component={MostUpvotedProducts}
        />
        <Route
          exact
          path='/most-viewed-products'
          component={MostViewedProducts}
        />
        <Route exact path='/search/:query' component={PlatformSearch} />
        <Route exact path='/privacy-policy' component={PrivacyPolicy} />
        <Route exact path='/product/:id' component={Product} />
        {/* <Route path="/product/:id/question/:qid" component={QuestionDetails} /> */}
        <Route path='/product/:id/review/:rid' component={ReviewDetails} />
        <Route path='/profile/:username' component={ReportUser} />
        <Route exact path='/search' component={MSearch} />
        <Route path='/shop/session/:sessionId' component={ShopTogether} />
        <Route exact path='/terms-of-service' component={TermsOfService} />
        <Route exact path='/user-campaign' component={UserCampaign} />

        {/* public routes end */}

        {/* Restricted routes start */}
        <RestrictedRoute exact path='/sign-in' component={UserAuth} />
        {/* Restricted routes end */}

        {/* protected routes start */}
        <PrivateRoute exact path='/user-profile' component={UserProfile} />
        <PrivateRoute exact path='/my-account' component={MyAccount} />
        {/* protected routes end */}

        {/* Error route */}
        <Route
          path='/internal-server-error'
          component={() => (
            <ErrorPages
              title={STRING_CONFIG.inPage.common.txtError500Title}
              subtitle={STRING_CONFIG.inPage.platform.txtError500Subtext}
              img={internalServerError}
            />
          )}
        />
        <Route
          path='*'
          component={() => (
            <ErrorPages
              title={STRING_CONFIG.inPage.common.txtError404Title}
              subtitle={STRING_CONFIG.inPage.platform.txtError404Subtext}
              img={NotFound404}
            />
          )}
        />
        <Route
          path='/page-not-found'
          component={() => (
            <ErrorPages
              title={STRING_CONFIG.inPage.common.txtError404Title}
              subtitle={STRING_CONFIG.inPage.platform.txtError404Subtext}
              img={NotFound404}
            />
          )}
        />
      </Switch>
      <Toast />
      {showAdTray && <AdTray />}
    </Suspense>
  );
}

export default withRouter(Routes);
