import React from 'react';

function Quality({ fill = '#011726' }) {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill={fill}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.00008 0.666748C4.40008 0.666748 0.666748 4.40008 0.666748 9.00008C0.666748 13.6001 4.40008 17.3334 9.00008 17.3334C13.6001 17.3334 17.3334 13.6001 17.3334 9.00008C17.3334 4.40008 13.6001 0.666748 9.00008 0.666748ZM9.00008 15.6667C5.32508 15.6667 2.33341 12.6751 2.33341 9.00008C2.33341 5.32508 5.32508 2.33341 9.00008 2.33341C12.6751 2.33341 15.6667 5.32508 15.6667 9.00008C15.6667 12.6751 12.6751 15.6667 9.00008 15.6667Z'
        fill={fill}
        fillOpacity='0.3'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.2417 8.48336C10.6 7.4667 10.3834 6.28336 9.5667 5.4667C8.6417 4.5417 7.2417 4.38336 6.15003 4.97503L8.10836 6.93336L6.93336 8.10836L4.97503 6.15003C4.38336 7.25003 4.5417 8.6417 5.4667 9.5667C6.28336 10.3834 7.4667 10.6 8.48336 10.2417L11.325 13.0834C11.4917 13.25 11.75 13.25 11.9167 13.0834L13.0834 11.9167C13.25 11.75 13.25 11.4917 13.0834 11.325L10.2417 8.48336Z'
        fill={fill}
        fillOpacity='0.3'
      />
    </svg>
  );
}

export default Quality;
