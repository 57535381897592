/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

function PrivateRoute({ component: Component, ...rest }) {
  const authToken = useSelector(({ auth }) => auth.token);

  return (
    <Route
      {...rest}
      render={(props) =>
        authToken ? (
          <Component {...props} />
        ) : (
          // If user isn't logged in then we can redirect to a login page.
          <Redirect
            to={{
              pathname: '/sign-in',
            }}
          />
          // window.location.href = URL
        )
      }
    />
  );
}

export default PrivateRoute;
