import React, { forwardRef } from 'react';
import { BoxStyled } from './style';

/* eslint-disable react/display-name, react/jsx-props-no-spreading */
const Box = forwardRef(({ children, ...props }, ref) => (
  <BoxStyled ref={ref} {...props} data-testid='box'>
    {children}
  </BoxStyled>
));

export default Box;
