import React from 'react';
import LinkStyled from './style';

function StyledLink({ children, ...props }) {
  return (
    <LinkStyled {...props} data-testid='StyledLink'>
      {children}
    </LinkStyled>
  );
}

export default StyledLink;
