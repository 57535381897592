import React, { useEffect, useRef, useState } from 'react';
import useClickOutside from 'helpers';

function Modal({ isShown, onHide, children, className, label }) {
  const modalRef = useRef(null);

  const [prevFocusedElement, setPrevFocusedElement] = useState(null);

  useClickOutside(modalRef, onHide);

  useEffect(() => {
    const modalElement = modalRef.current;
    if (isShown && modalElement) {
      setPrevFocusedElement(document.activeElement);
      const firstFocusableElement = modalRef.current.querySelector(
        'button, a, [href], input:not([type="range"]), select, textarea, [tabindex]:not([tabindex="-1"])'
      );
      if (firstFocusableElement) {
        firstFocusableElement.focus();
      }
    } else if (modalElement) {
      if (prevFocusedElement) {
        prevFocusedElement.focus();
      }
    }
  }, [isShown]);

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      onHide();
    }
    if (event.key === 'Tab' && modalRef.current) {
      const focusableElements = modalRef.current.querySelectorAll(
        'button, a, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      );
      if (focusableElements.length > 0) {
        const firstElement = focusableElements[0];

        const lastElement = focusableElements[focusableElements.length - 1];

        const { activeElement } = document;
        if (event.shiftKey && activeElement === firstElement) {
          event.preventDefault();

          lastElement.focus();
        } else if (!event.shiftKey && activeElement === lastElement) {
          event.preventDefault();

          firstElement.focus();
        }
      }
    }
  };

  return (
    <>
      {isShown && (
        <div
          aria-modal='true'
          aria-label={label}
          className={className}
          onKeyDown={handleKeyDown}
          ref={modalRef}
          role='dialog'
          tabIndex={-1}
        >
          {children}
        </div>
      )}
    </>
  );
}

export default Modal;
