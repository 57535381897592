import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Box from 'ReusableComponents/Box';
import { activeIndex } from 'store/auth/actions';
import { setActiveArticle } from 'store/platform/actions';
import styled from 'styled-components';
import { DESIGN_CONFIG } from 'utils';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa6';

const DropdownContainer = styled.div`
  position: relative;
  width: 100%;
`;

const DropdownHeader = styled.div`
  color: ${(props) =>
    props.index === 3
      ? DESIGN_CONFIG.color.primary.primaryMain
      : DESIGN_CONFIG.color.text.text500};
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 400;
`;

const DropdownList = styled.ul`
  list-style: none;
  margin: 0;
  max-height: ${(props) => (props.isOpen ? '200px' : '0')};
  overflow-y: hidden;
  padding: 0;
  transition: max-height 0.3s ease;
`;

const DropdownListItem = styled.li`
  border: none !important;
  color: ${(props) =>
    props.isSelected
      ? DESIGN_CONFIG.color.primary.primaryMain
      : DESIGN_CONFIG.color.text.text200};
  cursor: pointer;
  font-size: 10px !important;
  font-weight: ${(props) => (props.isSelected ? 600 : 400)};
  padding: 0.625rem 0 0 0 !important;
`;

function Dropdown({ options, index, activeArticleId }) {
  const dispatch = useDispatch();

  const history = useHistory();

  const [isOpen, setIsOpen] = useState(true);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (idx) => {
    dispatch(setActiveArticle(idx));
  };

  return (
    <DropdownContainer>
      <Box
        alignItems='center'
        display='flex'
        justifyContent='space-between'
        onClick={() => {
          history.push('/articles');

          dispatch(activeIndex(3));
        }}
      >
        <DropdownHeader index={index}>Articles</DropdownHeader>
        <Box
          onClick={(e) => {
            e.stopPropagation();

            handleToggle();
          }}
          position='relative'
          top='2px'
        >
          {isOpen ? <FaAngleUp /> : <FaAngleDown />}
        </Box>
      </Box>
      <DropdownList isOpen={isOpen}>
        {options.map((option, idx) => (
          <DropdownListItem
            isSelected={idx + 1 === activeArticleId}
            // eslint-disable-next-line react/no-array-index-key
            key={idx}
            onClick={() => handleSelect(idx + 1)}
          >
            {idx + 1}. {option}
          </DropdownListItem>
        ))}
      </DropdownList>
    </DropdownContainer>
  );
}

export default Dropdown;
