import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './style.css';
import { hideToast } from 'store/platform/actions';
import { HiOutlineExclamation, HiOutlineX } from 'react-icons/hi';
import { DESIGN_CONFIG } from 'utils';

function Toast() {
  const toastState = useSelector((state) => state.platform);

  const dispatch = useDispatch();

  useEffect(() => {
    if (toastState.show && !toastState.persistent) {
      const timer = setTimeout(() => {
        dispatch(hideToast());
      }, toastState.duration);

      return () => clearTimeout(timer);
    }
  }, [toastState.show, toastState.duration, toastState.persistent, dispatch]);

  return (
    <div
      className={`toast ${toastState.show ? 'show' : 'hide'} toast-${toastState.type}`}
      data-testid='toast'
    >
      <HiOutlineExclamation
        color={DESIGN_CONFIG.color.neutral.neutral500}
        size={24}
      />
      <p>{toastState.message}</p>
      {toastState.closable && (
        <HiOutlineX
          className='toast-close'
          color='white'
          onClick={() => dispatch(hideToast())}
          size={20}
        />
      )}
    </div>
  );
}

export default Toast;
