import React from 'react';
import ErrorPages from 'ReusableComponents/ErrorPages';
import Error500 from 'assets/Error500.svg';
import { STRING_CONFIG } from 'utils';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = { hasError: false };
  }

  // eslint-disable-next-line no-unused-vars
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line no-console
    console.log(error, errorInfo);
  }

  render() {
    // eslint-disable-next-line react/destructuring-assignment
    if (this.state.hasError) {
      return (
        <ErrorPages
          title={
            STRING_CONFIG.inPage.platform.txtErrorSiteUnderMaintenanceTitle
          }
          subtitle={
            STRING_CONFIG.inPage.platform.txtErrorSiteUnderMaintenanceSubtext
          }
          img={Error500}
        />
      );
    }

    // eslint-disable-next-line react/destructuring-assignment
    return this.props.children;
  }
}

export default ErrorBoundary;
