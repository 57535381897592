/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Link } from 'react-router-dom';
import StyledLink from 'ReusableComponents/Link';
import Text from 'ReusableComponents/Text';
import { useMedia } from 'helpers';
import { DESIGN_CONFIG, STRING_CONFIG } from 'utils';
import Box from '../../ReusableComponents/Box';
import './style.css';

function Footer() {
  const smallDevice = useMedia('(max-width:500px)');

  const menuData = [
    {
      menuTitle: STRING_CONFIG.inPage.platform.txtHeaderReviewDale,
      links: [
        { title: STRING_CONFIG.inPage.platform.lnkNavBarHome, url: '/home' },
        { title: STRING_CONFIG.inPage.platform.lnkFooterAbout, url: '/about' },
        {
          title: STRING_CONFIG.inPage.platform.lnkFooterCorporate,
          url: '/corporate',
        },
        {
          title: STRING_CONFIG.inPage.platform.lnkFooterHelpCenter,
          url: '/help-center',
        },
      ],
    },
    {
      menuTitle: STRING_CONFIG.inPage.platform.txtFooterContactUs,
      links: [
        {
          title: STRING_CONFIG.inPage.platform.lnkFooterEmail,
          url: `mailto:${STRING_CONFIG.inPage.platform.lnkFooterEmail}`,
          subTitle: 'Email id',
        },
      ],
      showSocialIcons: true,
    },
  ];
  return (
    <footer className='footer'>
      <Box margin='auto' maxW={DESIGN_CONFIG.size.footerWidth} w='100%'>
        <Box
          display='flex'
          gap='.75rem'
          margin={smallDevice ? '1.75rem 0 0 0' : '2.5rem 0 1rem 0'}
        >
          <img
            alt='Reviewdale Logo'
            height={smallDevice ? 28 : 48}
            width={smallDevice ? 168 : 288}
            src='/assets/logos/reviewdaleInverted.svg'
          />
        </Box>
        <Box
          display={smallDevice && 'flex'}
          justifyContent={smallDevice && 'space-between'}
        >
          {menuData.map((menu, index) => (
            <Box key={index}>
              <h2
                style={{
                  color: `${DESIGN_CONFIG.color.primary.primary100}`,
                  fontSize: `${smallDevice ? '1rem' : DESIGN_CONFIG.typography.common.webLabel.fontSize}`,
                  fontWeight:
                    DESIGN_CONFIG.typography.common.webLabel.fontWeight,
                  marginTop: '1.5rem',
                }}
              >
                {menu.menuTitle}
              </h2>
              <Box
                display='flex'
                flexDirection={smallDevice && 'column'}
                flexWrap='wrap'
                gap={smallDevice ? '1rem' : '1.5rem'}
              >
                {menu.links.map((link, i) => (
                  <>
                    {smallDevice && link.subTitle && (
                      <h3
                        style={{
                          color: `${DESIGN_CONFIG.color.primary.primary200}`,
                          fontSize: `${DESIGN_CONFIG.typography.mainCard.webMnCardTitle.fontSize}`,
                          fontWeight:
                            DESIGN_CONFIG.typography.mainCard.webMnCardTitle
                              .fontWeight,
                          marginBottom: '0',
                        }}
                      >
                        {link.subTitle}
                      </h3>
                    )}
                    {link.url.startsWith('mailto:') ? (
                      <a
                        key={i}
                        href={link.url}
                        style={{
                          color: DESIGN_CONFIG.color.neutral.neutral500,
                          fontSize:
                            DESIGN_CONFIG.typography.common.webLabel.fontSize,
                          fontWeight:
                            DESIGN_CONFIG.typography.common.webLabel.fontWeight,
                          textDecoration: 'none',
                        }}
                      >
                        {link.title}
                      </a>
                    ) : (
                      <Link
                        key={i}
                        to={link.url}
                        style={{
                          color: DESIGN_CONFIG.color.neutral.neutral500,
                          fontSize:
                            DESIGN_CONFIG.typography.common.webLabel.fontSize,
                          fontWeight:
                            DESIGN_CONFIG.typography.common.webLabel.fontWeight,
                        }}
                      >
                        {link.title}
                      </Link>
                    )}
                  </>
                ))}
                {menu.showSocialIcons && smallDevice ? (
                  <Box marginTop={smallDevice ? '0' : '2.5rem'}>
                    <p
                      style={{
                        color: `${DESIGN_CONFIG.color.primary.primary100}`,
                        fontSize: '.875rem',
                        fontWeight: 400,
                        marginBottom: `${smallDevice && '.5rem'}`,
                      }}
                    >
                      {STRING_CONFIG.inPage.platform.txtFooterFollow}
                    </p>
                    <Box display='flex' gap='1rem' marginTop='.5rem'>
                      <a
                        href='https://www.facebook.com/profile.php?id=61563472181775'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <img
                          src='/assets/icons/social/Facebook.svg'
                          alt='facebook'
                        />
                      </a>
                      <a
                        href='https://x.com/reviewdale'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <img src='/assets/icons/social/X.svg' alt='x' />
                      </a>
                      <a
                        href='https://www.instagram.com/reviewdale_official/'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <img
                          src='/assets/icons/social/Instagram.svg'
                          alt='instagram'
                        />
                      </a>
                      <a
                        href='https://www.linkedin.com/company/reviewdale'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <img
                          src='/assets/icons/social/LinkedIn.svg'
                          alt='linkedin'
                        />
                      </a>
                    </Box>
                  </Box>
                ) : null}
              </Box>
            </Box>
          ))}
        </Box>
        <Box marginTop='2.5rem' display={smallDevice && 'none'}>
          <p
            style={{
              color: `${DESIGN_CONFIG.color.primary.primary100}`,
              fontSize: `${DESIGN_CONFIG.typography.common.webLabel.fontSize}`,
              fontWeight: DESIGN_CONFIG.typography.common.webLabel.fontWeight,
            }}
          >
            {STRING_CONFIG.inPage.platform.txtFooterFollow}
          </p>
          <Box display='flex' gap='1rem' marginTop='.5rem'>
            <a
              href='https://www.facebook.com/profile.php?id=61563472181775'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src='/assets/icons/social/Facebook.svg' alt='facebook' />
            </a>
            <a
              href='https://x.com/reviewdale'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src='/assets/icons/social/X.svg' alt='x' />
            </a>
            <a
              href='https://www.instagram.com/reviewdale_official/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src='/assets/icons/social/Instagram.svg' alt='instagram' />
            </a>
            <a
              href='https://www.linkedin.com/company/reviewdale'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src='/assets/icons/social/LinkedIn.svg' alt='linkedin' />
            </a>
          </Box>
        </Box>
        <Box
          alignItems='center'
          bt={`1px solid ${DESIGN_CONFIG.color.primary.primary200}`}
          display='flex'
          flexDirection={smallDevice && 'column'}
          gap={smallDevice && '.5rem'}
          justifyContent='space-between'
          marginTop={smallDevice ? '1.5rem' : '2.5rem'}
          padding='1.5rem 0 2.5rem 0'
        >
          <Box display='flex'>
            <StyledLink
              color={DESIGN_CONFIG.color.neutral.neutral500}
              fontSize={
                smallDevice
                  ? '.75rem'
                  : `${DESIGN_CONFIG.typography.common.webLabel.fontSize}`
              }
              fontWeight={DESIGN_CONFIG.typography.common.webLabel.fontWeight}
              to='/terms-of-service'
            >
              {STRING_CONFIG.inPage.platform.lnkFooterTermsOfService}
            </StyledLink>
            <Text
              fontSize={DESIGN_CONFIG.typography.common.webLabel.fontSize}
              fontWeight={DESIGN_CONFIG.typography.common.webLabel.fontWeight}
              margin='0 2px'
              textColor={DESIGN_CONFIG.color.neutral.neutral500}
            >
              •
            </Text>
            <StyledLink
              color={DESIGN_CONFIG.color.neutral.neutral500}
              fontSize={
                smallDevice
                  ? '.75rem'
                  : `${DESIGN_CONFIG.typography.common.webLabel.fontSize}`
              }
              fontWeight={DESIGN_CONFIG.typography.common.webLabel.fontWeight}
              to='/privacy-policy'
            >
              {STRING_CONFIG.inPage.platform.lnkFooterPrivacyPolicy}
            </StyledLink>
            <Text
              fontSize={DESIGN_CONFIG.typography.common.webLabel.fontSize}
              fontWeight={DESIGN_CONFIG.typography.common.webLabel.fontWeight}
              margin='0 2px'
              textColor={DESIGN_CONFIG.color.neutral.neutral500}
            >
              •
            </Text>
            <StyledLink
              color={DESIGN_CONFIG.color.neutral.neutral500}
              fontSize={
                smallDevice
                  ? '.75rem'
                  : `${DESIGN_CONFIG.typography.common.webLabel.fontSize}`
              }
              fontWeight={DESIGN_CONFIG.typography.common.webLabel.fontWeight}
              to='/community-guidelines'
            >
              {STRING_CONFIG.inPage.platform.lnkFooterCommunityGuidelines}
            </StyledLink>
          </Box>
          <Text
            fontSize={
              smallDevice
                ? '0.625rem'
                : DESIGN_CONFIG.typography.common.webLabel.fontSize
            }
            fontWeight={DESIGN_CONFIG.typography.common.webLabel.fontWeight}
            margin='0'
            textColor={DESIGN_CONFIG.color.neutral.neutral500}
          >
            {STRING_CONFIG.inPage.platform.txtFooterCopyright}
          </Text>
        </Box>
      </Box>
    </footer>
  );
}

export default Footer;
