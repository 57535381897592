import * as actionTypes from 'store/product/actionTypes';
import {
  DISPLAY_FEATURES,
  CONNECTIVITY_FEATURES,
  CAMERA_FEATURES,
  GENERAL_FEATURES,
  PERFORMANCE,
  REVIEWS,
  STORAGE,
} from 'utils/constants';

const initialState = {
  compareProducts: [],
  CONNECTIVITY_FEATURES,
  CAMERA_FEATURES,
  DISPLAY_FEATURES,
  GENERAL_FEATURES,
  PERFORMANCE,
  REVIEWS,
  STORAGE,
};

// eslint-disable-next-line default-param-last
export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.COMPARE_PRODUCTS: {
      const { compareProducts } = state;

      const { payload } = action;

      const isPresent = compareProducts.find(
        (item) => item.name === payload.name
      );
      return {
        ...state,
        compareProducts: !isPresent
          ? [...compareProducts, action.payload]
          : compareProducts,
      };
    }

    case actionTypes.CHECK_PRODUCT_DISPLAY: {
      const { DISPLAY_FEATURES } = state;

      const { payload } = action;
      return {
        ...state,
        DISPLAY_FEATURES: DISPLAY_FEATURES.map((item) =>
          item.name === payload ? { ...item, checked: !item.checked } : item
        ),
      };
    }
    case actionTypes.REMOVE_FROM_COMPARE_PRODUCTS: {
      const { compareProducts } = state;

      const { payload } = action;

      return {
        ...state,
        compareProducts: compareProducts.filter(
          (item) => item.name !== payload
        ),
      };
    }

    case actionTypes.CHECK_PRODUCT_CONNECTIVITY: {
      const { CONNECTIVITY_FEATURES } = state;

      const { payload } = action;
      return {
        ...state,
        CONNECTIVITY_FEATURES: CONNECTIVITY_FEATURES.map((item) =>
          item.name === payload ? { ...item, checked: !item.checked } : item
        ),
      };
    }
    case actionTypes.CHECK_PRODUCT_CAMERA: {
      const { CAMERA_FEATURES } = state;

      const { payload } = action;
      return {
        ...state,
        CAMERA_FEATURES: CAMERA_FEATURES.map((item) =>
          item.name === payload ? { ...item, checked: !item.checked } : item
        ),
      };
    }

    case actionTypes.CHECK_PRODUCT_CAMERA_ALL: {
      const { CAMERA_FEATURES } = state;

      return {
        ...state,
        CAMERA_FEATURES: CAMERA_FEATURES.map((item) => ({
          ...item,
          checked: !item.checked,
        })),
      };
    }

    case actionTypes.CHECK_PRODUCT_GENERAL: {
      const { GENERAL_FEATURES } = state;

      const { payload } = action;
      return {
        ...state,
        GENERAL_FEATURES: GENERAL_FEATURES.map((item) =>
          item.name === payload ? { ...item, checked: !item.checked } : item
        ),
      };
    }

    case actionTypes.CHECK_PRODUCT_STORAGE: {
      const { STORAGE } = state;

      const { payload } = action;
      return {
        ...state,
        STORAGE: STORAGE.map((item) =>
          item.name === payload ? { ...item, checked: !item.checked } : item
        ),
      };
    }

    case actionTypes.CHECK_PRODUCT_PERFORMANCE: {
      const { PERFORMANCE } = state;

      const { payload } = action;
      return {
        ...state,
        PERFORMANCE: PERFORMANCE.map((item) =>
          item.name === payload ? { ...item, checked: !item.checked } : item
        ),
      };
    }

    case actionTypes.CHECK_PRODUCT_REVIEWS: {
      const { REVIEWS } = state;

      const { payload } = action;
      return {
        ...state,
        REVIEWS: REVIEWS.map((item) =>
          item.name === payload ? { ...item, checked: !item.checked } : item
        ),
      };
    }

    case actionTypes.CHECK_PRODUCT_GENERAL_ALL: {
      const { GENERAL_FEATURES } = state;

      return {
        ...state,
        GENERAL_FEATURES: GENERAL_FEATURES.map((item) => ({
          ...item,
          checked: !item.checked,
        })),
      };
    }

    default: {
      return state;
    }
  }
};
