import React from 'react';

function Speed({ fill = '#011726' }) {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill={fill}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.9583 8.68335L16.9833 7.14169L16.9916 7.13335C17.8272 8.42195 18.2904 9.91638 18.3301 11.4516C18.3698 12.9869 17.9845 14.5033 17.2166 15.8334C17.0698 16.0877 16.8584 16.2987 16.6037 16.4451C16.3491 16.5914 16.0603 16.6679 15.7666 16.6667H4.22498C3.93412 16.6649 3.64879 16.5871 3.39734 16.4409C3.14589 16.2947 2.93707 16.0852 2.79165 15.8334C1.86644 14.2145 1.51087 12.3322 1.78183 10.4874C2.05278 8.64258 2.9346 6.94196 4.28622 5.65753C5.63785 4.3731 7.38119 3.57908 9.23739 3.40247C11.0936 3.22586 12.9554 3.67687 14.525 4.68335L12.9833 5.70835C11.7016 5.06721 10.2475 4.85614 8.83643 5.10643C7.42537 5.35672 6.13252 6.05504 5.14955 7.09788C4.16657 8.14071 3.5458 9.47253 3.37926 10.8959C3.21273 12.3193 3.50929 13.7584 4.22498 15H15.775C16.3256 14.0445 16.6306 12.9672 16.6626 11.8648C16.6946 10.7624 16.4526 9.66925 15.9583 8.68335Z'
        fill={fill}
        fillOpacity='0.4'
      />
      <path
        d='M9.36593 13.2034C9.1636 13.1195 8.97978 12.9966 8.82499 12.8416C8.67003 12.6868 8.5471 12.503 8.46323 12.3007C8.37935 12.0983 8.33618 11.8815 8.33618 11.6624C8.33618 11.4434 8.37935 11.2265 8.46323 11.0242C8.5471 10.8219 8.67003 10.6381 8.82499 10.4833L15.9 5.7666L11.1833 12.8416C11.0285 12.9966 10.8447 13.1195 10.6424 13.2034C10.4401 13.2872 10.2232 13.3304 10.0042 13.3304C9.78513 13.3304 9.56826 13.2872 9.36593 13.2034Z'
        fill={fill}
        fillOpacity='0.4'
      />
    </svg>
  );
}

export default Speed;
