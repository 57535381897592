import React from 'react';
import Text from 'ReusableComponents/Text';
import Box from 'ReusableComponents/Box';
import { useHistory } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMedia } from 'helpers';
import './style.css';
import { DESIGN_CONFIG, STRING_CONFIG } from 'utils';
import Button from 'ReusableComponents/Button';

function Header() {
  const MQ450 = useMedia('(max-width:450px)');

  const MQ600 = useMedia('(max-width:600px)');

  const history = useHistory();

  return (
    <header className='home__header'>
      <Box display='flex' flexDirection='column' order={MQ450 && 2}>
        <h1 className='home__title'>
          {STRING_CONFIG.inPage.home.txtPageTitle}
        </h1>
        <Text
          color={DESIGN_CONFIG.color.text.text50}
          fontSize={
            MQ450
              ? '1rem'
              : `${DESIGN_CONFIG.typography.page.webPageSubtitle.fontSize}`
          }
          fontWeight={
            MQ450
              ? 400
              : DESIGN_CONFIG.typography.page.webPageSubtitle.fontWeight
          }
          lineHeight={
            MQ450
              ? '1.375rem'
              : `${DESIGN_CONFIG.typography.page.webPageSubtitle.lineHeight}`
          }
          margin={MQ600 ? '.25rem 0 .75rem 0' : '1rem 0 2.125rem 0'}
        >
          {STRING_CONFIG.inPage.home.txtPageSubtext}
        </Text>
        <Box
          display='flex'
          gap={MQ450 ? '1rem' : '2.5rem'}
          justifyContent='start'
        >
          <Button
            alignItems='center'
            background={DESIGN_CONFIG.color.primary.primaryMain}
            border='none'
            borderRadius={MQ450 ? '4px' : '6px'}
            cursor='pointer'
            flex={MQ450 ? 1 : 0}
            fontSize={
              MQ450
                ? '.875rem'
                : DESIGN_CONFIG.typography.common.webButton.fontSize
            }
            fontWeight={
              MQ450
                ? '600'
                : DESIGN_CONFIG.typography.common.webButton.fontWeight
            }
            justifyContent='center'
            onClick={() => history.push('/browse')}
            padding={MQ450 ? '.5rem 1rem' : '1rem 4.688rem'}
            textColor={DESIGN_CONFIG.color.neutral.neutral500}
            w='fit-content'
            whiteSpace='noWrap'
          >
            {STRING_CONFIG.inPage.common.btnBrowseProducts}
          </Button>
          <Button
            alignItems='center'
            background={DESIGN_CONFIG.color.neutral.neutral500}
            border={`1px solid ${DESIGN_CONFIG.color.primary.primaryMain}`}
            borderRadius={MQ450 ? '4px' : '6px'}
            cursor='pointer'
            display='flex'
            flex={MQ450 ? 1 : 0}
            fontSize={
              MQ450
                ? '.875rem'
                : DESIGN_CONFIG.typography.common.webButton.fontSize
            }
            fontWeight={
              MQ450
                ? '600'
                : DESIGN_CONFIG.typography.common.webButton.fontWeight
            }
            justifyContent='center'
            onClick={() => history.push('/compare')}
            padding={MQ450 ? '.5rem 1rem' : '1rem 4.063rem'}
            textColor={DESIGN_CONFIG.color.primary.primaryMain}
            w='fit-content'
            whiteSpace='noWrap'
          >
            {STRING_CONFIG.inPage.common.btnCompareProducts}
          </Button>
        </Box>
      </Box>
      <div className='home__banner'>
        <LazyLoadImage
          alt='people using reviewdale'
          effect='blur'
          src='/assets/HomeBanner.svg'
          style={{ objectFit: 'cover', width: '100%', height: '100%' }}
        />
      </div>
    </header>
  );
}

export default Header;
