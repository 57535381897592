import React, { useRef, useState, useEffect } from 'react';
import Box from 'ReusableComponents/Box';
import RatingBar from 'ReusableComponents/RatingBar';
import { useMedia } from 'helpers';
import { Helmet } from 'react-helmet';
import UserCampaignSliderBottom from 'Pages/Components/Sliders/UserCampaignBottom';
import FooterUserCampaign from 'Pages/Footer/FooterUserCampaign';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import UserCampaignSlider from 'Pages/Components/Sliders/UserCampaign';
import './style.css';
import Text from 'ReusableComponents/Text';
import { DESIGN_CONFIG, STRING_CONFIG } from 'utils';
import { useAdTray } from 'contexts/AdTrayContext';
import LaunchingSoon from './Modal';

function UserCampaign() {
  const [show, setShow] = useState(false);

  const [ratings, setRating] = useState(0);

  const [inputValue, setInputValue] = useState(0);

  const [hasUserSubmitted, setUserSubmittedStatus] = useState(false);

  const smallDevice = useMedia('(max-width:450px)');

  const MQ730 = useMedia('(max-width:730px)');

  const MQ450 = useMedia('(max-width:450px)');

  const [visible, setVisible] = useState(false);

  const openerButtonRef = useRef(null);

  const { setShowAdTray } = useAdTray();

  useEffect(() => {
    setShowAdTray(false);
  }, []);

  useEffect(() => {
    const feedbackRatings = localStorage.getItem('rd-feedback-rating');
    if (feedbackRatings) {
      setUserSubmittedStatus(true);

      setRating(feedbackRatings);
    }
  }, []);

  const handleChange = (newValue) => {
    setVisible(true);

    setInputValue(newValue);
  };

  const getRatingLabel = (value) => {
    if (value >= 0 && value < 2)
      return STRING_CONFIG.entityCaption.review.ratingSegment.one;
    if (value >= 2 && value < 3)
      return STRING_CONFIG.entityCaption.review.ratingSegment.two;
    if (value >= 3 && value < 4)
      return STRING_CONFIG.entityCaption.review.ratingSegment.three;
    if (value >= 4 && value < 5)
      return STRING_CONFIG.entityCaption.review.ratingSegment.four;
    if (value === 5)
      return STRING_CONFIG.entityCaption.review.ratingSegment.five;
    return '';
  };

  const getRatingStyles = (ratingsVal) => {
    let borderColor = null;
    let backgroundColor = null;
    let textColor = null;

    if (ratingsVal >= 0 && ratingsVal < 2) {
      borderColor = DESIGN_CONFIG.color.error.errorMain;

      backgroundColor = DESIGN_CONFIG.color.error.error50;

      textColor = DESIGN_CONFIG.color.error.errorMain;
    } else if (ratingsVal >= 2 && ratingsVal < 3) {
      borderColor = DESIGN_CONFIG.color.warning.warningMain;

      backgroundColor = DESIGN_CONFIG.color.warning.warning50;

      textColor = DESIGN_CONFIG.color.warning.warningMain;
    } else if (ratingsVal >= 3 && ratingsVal < 4) {
      borderColor = DESIGN_CONFIG.color.success.successMain;

      backgroundColor = DESIGN_CONFIG.color.success.success50;

      textColor = DESIGN_CONFIG.color.success.successMain;
    } else if (ratingsVal >= 4 && ratingsVal < 5) {
      borderColor = DESIGN_CONFIG.color.primary.primaryMain;

      backgroundColor = DESIGN_CONFIG.color.primary.primary50;

      textColor = DESIGN_CONFIG.color.primary.primaryMain;
    }

    return { borderColor, backgroundColor, textColor };
  };

  const getInputValueStyles = (inputVal) => {
    let borderColor = DESIGN_CONFIG.color.primary.primaryMain; // Default to primary
    let backgroundColor = DESIGN_CONFIG.color.primary.primary50; // Default to primary50
    let textColor = DESIGN_CONFIG.color.primary.primaryMain; // Default to primary

    if (inputVal >= 0 && inputVal < 2) {
      borderColor = DESIGN_CONFIG.color.error.errorMain;

      backgroundColor = DESIGN_CONFIG.color.error.error50;

      textColor = DESIGN_CONFIG.color.error.errorMain;
    } else if (inputVal >= 2 && inputVal < 3) {
      borderColor = DESIGN_CONFIG.color.warning.warningMain;

      backgroundColor = DESIGN_CONFIG.color.warning.warning50;

      textColor = DESIGN_CONFIG.color.warning.warningMain;
    } else if (inputVal >= 3 && inputVal < 4) {
      borderColor = DESIGN_CONFIG.color.success.successMain;

      backgroundColor = DESIGN_CONFIG.color.success.success50;

      textColor = DESIGN_CONFIG.color.success.successMain;
    } else if (inputVal >= 4 && inputVal < 5) {
      borderColor = DESIGN_CONFIG.color.primary.primaryMain;

      backgroundColor = DESIGN_CONFIG.color.primary.primary50;

      textColor = DESIGN_CONFIG.color.primary.primaryMain;
    } else if (inputVal === 5) {
      backgroundColor = DESIGN_CONFIG.color.gradient.highlight;

      textColor = DESIGN_CONFIG.color.neutral.neutral500;
    }

    return { borderColor, backgroundColor, textColor };
  };

  return (
    <>
      <div className='navigation'>
        <Box padding={smallDevice ? '.75rem 1rem' : '1.375rem 5rem'}>
          <img
            alt='reviewdale'
            height='28px'
            src='/assets/logos/reviewdaleMain.svg'
            width='168px'
          />
        </Box>
      </div>
      <Text
        alignItems='center'
        background={DESIGN_CONFIG.color.gradient.highlight}
        display='flex'
        fontSize={DESIGN_CONFIG.typography.common.webLabel.fontSize}
        fontWeight={DESIGN_CONFIG.typography.common.webLabel.fontWeight}
        justifyContent='center'
        margin={0}
        padding={smallDevice ? '1rem' : '1rem 0'}
        textAlign='center'
        textColor={DESIGN_CONFIG.color.neutral.neutral500}
      >
        Get excited! We are coming to your browser soon! 🚀
      </Text>
      {show ? (
        <LaunchingSoon
          inputValue={inputValue}
          openerButtonRef={openerButtonRef}
          setRating={setRating}
          setShow={setShow}
          setUserSubmittedStatus={setUserSubmittedStatus}
          show={show}
        />
      ) : null}
      <div className='top-page-container'>
        <main className='pre-mvp'>
          <section className='pre-mvp__section pre-mvp__section--1'>
            <div className='pre-mvp__section--1--left'>
              <h1>Discover your perfect phone match</h1>
              <p>
                Join a community of phone enthusiasts sharing real experiences
                and insights. Explore our vast phone library, get
                recommendations, and make informed decisions. Share your phone
                story and help others find their perfect fit.
              </p>
              {hasUserSubmitted ? (
                <div className='pre-mvp__user-feedback-card'>
                  <h2 className='heading'>Thanks for sharing your thoughts!</h2>
                  <p className='paragraph'>
                    Your feedback will help us make ReviewDale better
                  </p>
                  <Box
                    alignItems='center'
                    display='flex'
                    justifyContent='space-between'
                  >
                    <div
                      style={{
                        alignItems: 'center',
                        display: 'flex',
                        gap: '.5rem',
                      }}
                    >
                      <img
                        src='/assets/rdLogo.svg'
                        alt=''
                        width={20}
                        height={20}
                      />
                      <p
                        style={{
                          color: `${DESIGN_CONFIG.color.text.text500}`,
                          fontSize: `${DESIGN_CONFIG.typography.section.webSectionSubtitle.fontSize}`,
                          fontWeight:
                            DESIGN_CONFIG.typography.section.webSectionSubtitle
                              .fontWeight,
                          marginBottom: '0',
                        }}
                      >
                        ReviewDale
                      </p>
                    </div>
                    <p
                      style={{
                        border: `1px solid ${getRatingStyles(ratings).borderColor}`,
                        borderRadius: '40px',
                        background: getRatingStyles(ratings).backgroundColor,
                        color: getRatingStyles(ratings).textColor,
                        fontSize: '1.125rem',
                        fontWeight: 600,
                        margin: '0',
                        padding: '.5rem 1.5rem',
                      }}
                    >
                      {getRatingLabel(ratings)}
                    </p>
                  </Box>
                  <div className='pre-mvp__range'>
                    <RatingBar width='100%' rating={ratings} />
                  </div>
                  <div
                    style={{
                      alignItems: 'center',
                      color: `${DESIGN_CONFIG.color.text.text75}`,
                      display: 'flex',
                      fontSize: '1.125rem',
                      fontWeight: 400,
                      justifyContent: 'space-between',
                    }}
                  >
                    <span
                      style={{
                        fontSize: `${DESIGN_CONFIG.typography.common.webButton.fontSize}`,
                        fontWeight:
                          DESIGN_CONFIG.typography.common.webButton.fontWeight,
                      }}
                    >
                      Bad
                    </span>
                    <span
                      style={{
                        fontSize: `${DESIGN_CONFIG.typography.common.webButton.fontSize}`,
                        fontWeight:
                          DESIGN_CONFIG.typography.common.webButton.fontWeight,
                      }}
                    >
                      Good
                    </span>
                  </div>
                </div>
              ) : (
                <div className='pre-mvp__user-feedback-card'>
                  <h2 className='heading'>
                    Help us by rating{' '}
                    <i style={{ fontSize: 'inherit' }}>ReviewDale</i>
                  </h2>
                  <p className='paragraph'>
                    How helpful would a Reviewing platform for phones be for
                    you?
                  </p>
                  <Box
                    alignItems='center'
                    display='flex'
                    justifyContent='space-between'
                  >
                    <div
                      style={{
                        alignItems: 'center',
                        display: 'flex',
                        gap: '.5rem',
                      }}
                    >
                      <img
                        src='/assets/rdLogo.svg'
                        alt=''
                        width={20}
                        height={20}
                      />
                      <p
                        style={{
                          color: `${DESIGN_CONFIG.color.text.text500}`,
                          fontSize: `${DESIGN_CONFIG.typography.common.webLabel.fontSize}`,
                          fontWeight:
                            DESIGN_CONFIG.typography.common.webLabel.fontWeight,
                          marginBottom: '0',
                        }}
                      >
                        ReviewDale
                      </p>
                    </div>
                    <p
                      style={{
                        border: `1px solid ${getInputValueStyles(inputValue).borderColor}`,
                        borderRadius: '40px',
                        background:
                          getInputValueStyles(inputValue).backgroundColor,
                        color: getInputValueStyles(inputValue).textColor,
                        display: `${visible ? '' : 'none'}`,
                        fontSize: `${DESIGN_CONFIG.typography.common.webTag.fontSize}`,
                        fontWeight:
                          DESIGN_CONFIG.typography.common.webTag.fontWeight,
                        margin: '0',
                        padding: '.5rem 1.5rem',
                      }}
                    >
                      {getRatingLabel(inputValue)}
                    </p>
                  </Box>
                  <div className='pre-mvp__range'>
                    <UserCampaignSlider
                      inputValue={inputValue}
                      onChange={handleChange}
                    />
                  </div>
                  <div
                    style={{
                      alignItems: 'center',
                      color: `${DESIGN_CONFIG.color.text.text200}`,
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <span
                      style={{
                        fontSize: `${DESIGN_CONFIG.typography.common.webButton.fontSize}`,
                        fontWeight:
                          DESIGN_CONFIG.typography.common.webButton.fontWeight,
                      }}
                    >
                      Bad
                    </span>
                    <span
                      style={{
                        fontSize: `${DESIGN_CONFIG.typography.common.webButton.fontSize}`,
                        fontWeight:
                          DESIGN_CONFIG.typography.common.webButton.fontWeight,
                      }}
                    >
                      Good
                    </span>
                  </div>
                  <div className='pre-mvp_button'>
                    <button
                      style={{
                        fontSize: `${smallDevice ? '.75rem' : DESIGN_CONFIG.typography.common.webButton.fontSize}`,
                        fontWeight: `${smallDevice ? 600 : DESIGN_CONFIG.typography.common.webButton.fontWeight}`,
                        width: `${MQ450 && '100%'}`,
                      }}
                      onClick={() => setShow(true)}
                      type='button'
                    >
                      Submit
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className='pre-mvp__section--1--right'>
              <LazyLoadImage
                alt='banner image'
                effect='blur'
                height='100%'
                src='/assets/banner.svg'
                width='100%'
              />
            </div>
          </section>
          <section className='pre-mvp__section pre-mvp__section--2'>
            <div>
              <LazyLoadImage
                alt='introducing reviewdale'
                effect='blur'
                height='100%'
                src='/assets/Introducing.svg'
                width='100%'
              />
            </div>
            <div>
              <h2 className='pre-mvp__heading'>Introducing ReviewDale</h2>
              <p className='pre-mvp__paragraph'>
                ReviewDale helps you choose. Find honest reviews, personalized
                picks, and clear comparisons. <i>Dive in!</i>
              </p>
            </div>
          </section>
          <section className='pre-mvp__section pre-mvp__section--3'>
            <div>
              <h2 className='pre-mvp__heading'>
                Before you buy a Phone, visit ReviewDale
              </h2>
              <p className='pre-mvp__paragraph'>
                More than 70% internet users read online reviews before finding
                their perfect phone. ReviewDale is a community of passionate
                people like you, who are looking for the right phone.
              </p>
            </div>
            <div>
              <LazyLoadImage
                alt='visit reviewdale'
                effect='blur'
                height='100%'
                src='/assets/VisitReviewDale.svg'
                width='100%'
              />
            </div>
          </section>
          <section className='pre-mvp__section--4'>
            <h2
              className='pre-mvp__heading'
              style={{ textAlign: `${smallDevice ? 'start' : 'center'}` }}
            >
              Find meaningful Reviews
            </h2>
            <p
              className='pre-mvp__paragraph'
              style={{
                maxWidth: '50.25rem',
                margin: 'auto',
                textAlign: `${smallDevice ? 'start' : 'center'}`,
              }}
            >
              ReviewDale is a unified experience to discover meaningful phone
              reviews. Our reviews are structured to give you information that
              matters. It&rsquo;s like knowing the phone even before owning it!
            </p>
            <div
              style={{
                display: 'flex',
                gap: '2.5rem',
                justifyContent: 'space-between',
                marginTop: `${MQ450 ? '1rem' : ''}`,
              }}
            >
              <div
                style={{
                  height: '15.625rem',
                  width: '12.5rem',
                }}
              >
                <LazyLoadImage
                  alt='Iphone 13'
                  effect='blur'
                  height='100%'
                  src={
                    MQ450
                      ? '/assets/samsungGalaxySmall.svg'
                      : '/assets/samsungGalaxyM14.svg'
                  }
                  style={{ objectFit: 'contain' }}
                  width='100%'
                />
              </div>
              <div
                style={{
                  display: `${MQ730 ? 'none' : 'block'}`,
                  height: '15.625rem',
                  width: '12.5rem',
                }}
              >
                <LazyLoadImage
                  alt='Iphone 13'
                  effect='blur'
                  height='100%'
                  src='/assets/iqooZ7Pro.svg'
                  style={{ objectFit: 'contain' }}
                  width='100%'
                />
              </div>
              <div
                style={{
                  display: `${MQ730 ? 'none' : 'block'}`,
                  height: '15.625rem',
                  width: '12.5rem',
                }}
              >
                <LazyLoadImage
                  alt='Iphone 13'
                  effect='blur'
                  height='100%'
                  src='/assets/oneplusNordCE3.svg'
                  style={{ objectFit: 'contain' }}
                  width='100%'
                />
              </div>
              <div
                style={{
                  height: '15.625rem',
                  width: '12.5rem',
                }}
              >
                <LazyLoadImage
                  alt='redmi'
                  effect='blur'
                  height='100%'
                  src={
                    MQ450
                      ? '/assets/samsungGalaxySmall.svg'
                      : '/assets/realmeNarzo70.svg'
                  }
                  style={{ objectFit: 'contain' }}
                  width='100%'
                />
              </div>
            </div>
          </section>
          <section className='pre-mvp__section--5'>
            <h2
              className='pre-mvp__heading'
              style={{
                margin: '0 auto .5rem auto',
                maxWidth: '56.25rem',
                textAlign: `${smallDevice ? 'start' : 'center'}`,
              }}
            >
              You deserve real Reviews
            </h2>
            <p
              className='pre-mvp__paragraph'
              style={{
                textAlign: `${smallDevice ? 'start' : 'center'}`,
                margin: `${MQ450 ? '0 auto 1rem auto' : '0 auto 1.5rem auto'}`,
                maxWidth: '56.25rem',
              }}
            >
              ReviewDale is committed to becoming the platform of choice for
              honest, meaningful and unbiased phone reviews. Our journey has
              just begun!
            </p>
            <Box display='flex' justifyContent='center' width='100%'>
              <img
                alt='you deserve real reviews'
                src='/assets/userCampaignProduct.svg'
                style={{ width: '100%' }}
              />
            </Box>
          </section>
          <section className='pre-mvp__section--6'>
            <h2
              className='pre-mvp__heading'
              style={{ textAlign: `${smallDevice ? 'start' : 'center'}` }}
            >
              Ease of our Experience-metric Reviews
            </h2>
            <p
              className='pre-mvp__paragraph'
              style={{
                textAlign: `${smallDevice ? 'start' : 'center'}`,
                maxWidth: '56.25rem',
                margin: `${MQ450 ? '0 auto 1rem auto' : '0 auto 2.5rem auto'}`,
              }}
            >
              We understand that your needs are unique, our Experience-metric
              based Reviews makes it faster & easier for you to decide. We keep
              researching for simpler ways for you to buy your next best phone.
            </p>
            <Box
              display='flex'
              justifyContent='center'
              marginBottom='1.5rem'
              width='100%'
            >
              <img
                alt='Ease Of Experience'
                src={
                  smallDevice
                    ? '/assets/EaseOfExperienceMetricsSmall.svg'
                    : '/assets/userCampaignMetrics.svg'
                }
                style={{ width: '100%' }}
              />
            </Box>
          </section>
          <section
            style={{
              display: 'flex',
              flexDirection: `${MQ450 ? 'column' : ''}`,
              gap: `${MQ450 ? '1rem' : '2.5rem'}`,
              justifyContent: 'space-between',
            }}
          >
            <Box
              h={MQ450 ? '100%' : '18.063rem'}
              margin={MQ450 ? '.5rem 0 0 0' : '0'}
              w={MQ450 ? '100%' : '25rem'}
            >
              <img
                src='/assets/userCampaignBottomCard.svg'
                style={{ objectFit: 'contain' }}
                alt=''
              />
            </Box>
            {hasUserSubmitted ? (
              <div className='pre-mvp__user-feedback-card--bottom'>
                <h2 className='heading'>Thanks for sharing your thoughts!</h2>
                <p className='paragraph'>
                  Your feedback will help us make ReviewDale better
                </p>
                <Box
                  alignItems='center'
                  display='flex'
                  justifyContent='space-between'
                >
                  <div
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      gap: '.5rem',
                    }}
                  >
                    <img
                      src='/assets/rdLogo.svg'
                      alt=''
                      width={20}
                      height={20}
                    />
                    <p
                      style={{
                        color: `${DESIGN_CONFIG.color.text.text500}`,
                        fontSize: '1.125rem',
                        fontWeight: 600,
                        marginBottom: '0',
                      }}
                    >
                      ReviewDale
                    </p>
                  </div>
                  <p
                    style={{
                      border: `1px solid ${getRatingStyles(ratings).borderColor}`,
                      borderRadius: '40px',
                      background: getRatingStyles(ratings).backgroundColor,
                      color: getRatingStyles(ratings).textColor,
                      fontSize: `${DESIGN_CONFIG.typography.common.webTag.fontSize}`,
                      fontWeight:
                        DESIGN_CONFIG.typography.common.webTag.fontWeight,
                      margin: '0',
                      padding: '.5rem 1.5rem',
                    }}
                  >
                    {getRatingLabel(ratings)}
                  </p>
                </Box>
                <div className='pre-mvp__range'>
                  <RatingBar width='100%' rating={ratings} />
                </div>
                <div
                  style={{
                    alignItems: 'center',
                    color: `${DESIGN_CONFIG.color.text.text200}`,
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <span
                    style={{
                      fontSize: `${DESIGN_CONFIG.typography.common.webButton.fontSize}`,
                      fontWeight:
                        DESIGN_CONFIG.typography.common.webButton.fontWeight,
                    }}
                  >
                    Bad
                  </span>
                  <span
                    style={{
                      fontSize: `${DESIGN_CONFIG.typography.common.webButton.fontSize}`,
                      fontWeight:
                        DESIGN_CONFIG.typography.common.webButton.fontWeight,
                    }}
                  >
                    Good
                  </span>
                </div>
              </div>
            ) : (
              <div className='pre-mvp__user-feedback-card--bottom'>
                <h2 className='heading'>Excited enough?</h2>
                <p className='paragraph'>
                  Tell us how helpful will ReviewDale be
                </p>
                <Box
                  alignItems='center'
                  display='flex'
                  justifyContent='space-between'
                >
                  <div
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      gap: '.5rem',
                    }}
                  >
                    <img
                      src='/assets/rdLogo.svg'
                      alt=''
                      width={20}
                      height={20}
                    />
                    <p
                      style={{
                        color: `${DESIGN_CONFIG.color.text.text500}`,
                        fontSize: `${DESIGN_CONFIG.typography.section.webSectionSubtitle.fontSize}`,
                        fontWeight:
                          DESIGN_CONFIG.typography.section.webSectionSubtitle
                            .fontWeight,
                        marginBottom: '0',
                      }}
                    >
                      ReviewDale
                    </p>
                  </div>
                  <p
                    style={{
                      border: `1px solid ${getInputValueStyles(inputValue).borderColor}`,
                      borderRadius: '40px',
                      background:
                        getInputValueStyles(inputValue).backgroundColor,
                      color: getInputValueStyles(inputValue).textColor,
                      display: `${visible ? '' : 'none'}`,
                      fontSize: `${DESIGN_CONFIG.typography.common.webTag.fontSize}`,
                      fontWeight:
                        DESIGN_CONFIG.typography.common.webTag.fontWeight,
                      margin: '0',
                      padding: '.5rem 1.5rem',
                    }}
                  >
                    {getRatingLabel(inputValue)}
                  </p>
                </Box>
                <div className='pre-mvp__range'>
                  <UserCampaignSliderBottom
                    inputValue={inputValue}
                    onChange={handleChange}
                  />
                </div>
                <div
                  style={{
                    alignItems: 'center',
                    color: `${DESIGN_CONFIG.color.text.text75}`,
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <span
                    style={{
                      fontSize: `${DESIGN_CONFIG.typography.common.webButton.fontSize}`,
                      fontWeight:
                        DESIGN_CONFIG.typography.common.webButton.fontWeight,
                    }}
                  >
                    Bad
                  </span>
                  <span
                    style={{
                      fontSize: `${DESIGN_CONFIG.typography.common.webButton.fontSize}`,
                      fontWeight:
                        DESIGN_CONFIG.typography.common.webButton.fontWeight,
                    }}
                  >
                    Good
                  </span>
                </div>
                <div className='pre-mvp_button'>
                  <button
                    style={{
                      fontSize: `${smallDevice ? '.75rem' : DESIGN_CONFIG.typography.common.webButton.fontSize}`,
                      fontWeight: `${smallDevice ? 600 : DESIGN_CONFIG.typography.common.webButton.fontWeight}`,
                      width: `${MQ450 && '100%'}`,
                    }}
                    onClick={() => setShow(true)}
                    type='button'
                  >
                    Submit
                  </button>
                </div>
              </div>
            )}
          </section>
        </main>
      </div>
      <FooterUserCampaign />
      <Helmet>
        <title>ReviewDale</title>
        <meta
          name='description'
          content="With the vast number of options and features available today, we understand that choosing a phone can be tricky. That's why we created ReviewDale: A Product Community, to make it easier for you and everyone to share experiences with various phones, so go ahead and indulge!"
        />
        <meta name='keywords' content='Product, Reviews, Genuine Reviews' />
      </Helmet>
    </>
  );
}
export default UserCampaign;
