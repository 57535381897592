import * as actionTypes from './actionTypes';

export const storePlatformSearchInputValue = (data) => ({
  payload: data,
  type: actionTypes.PLATFORM_SEARCH,
});
export const clearPlatformSearchInputValue = (data) => ({
  payload: data,
  type: actionTypes.CLEAR_PLATFORM_SEARCH_INPUT_VALUE,
});
export const setActiveArticle = (id) => ({
  payload: id,
  type: actionTypes.ACTIVE_ARTICLE,
});
export const showToast = (
  // eslint-disable-next-line default-param-last
  closable = false,
  // eslint-disable-next-line default-param-last
  duration = 3000,
  message,
  persistent = false,
  type = 'info'
) => ({
  type: actionTypes.SHOW_TOAST,
  payload: {
    closable,
    duration,
    message,
    persistent,
    type,
  },
});
export const setOnlineStatus = (status) => ({
  payload: status,
  type: actionTypes.SET_ONLINE_STATUS,
});

export const hideToast = () => ({
  type: actionTypes.HIDE_TOAST,
});
