import * as actionTypes from './actionTypes';

const initialState = {
  r1: 0,
  r2: 0,
  r3: 0,
  r4: 0,
  r5: 0,
  r6: 0,
  r1per: 0,
  r2per: 0,
  r3per: 0,
  r4per: 0,
  r5per: 0,
  r6per: 0,
  r1main: 0,
  r2main: 0,
  r3main: 0,
  r4main: 0,
  r5main: 0,
  r6main: 0,
  r1secondary: 0,
  r2secondary: 0,
  r3secondary: 0,
  r4secondary: 0,
  r5secondary: 0,
  r6secondary: 0,
  overall: 0,
  overallmain: 0,
  overallsecondary: 0,
  summary: '',
  description: '',
};

// eslint-disable-next-line default-param-last
const RatingReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RATINGS: {
      return {
        ...state,
        // [action.payload.type]: action.payload.data.range,
        // [action.payload.percentType]: action.payload.data.percentage,
        // [action.payload.colorTypeMain]: action.payload.data.mainColor,
        // [action.payload.colorTypeSecory]: action.payload.data.secondaryColor,
        r1: action.payload?.r1,
        r2: action.payload?.r2,
        r3: action.payload?.r3,
        r4: action.payload?.r4,
        r5: action.payload?.r5,
        r6: action.payload?.r6,
        overall: action.payload?.overall,
      };
    }
    case actionTypes.SUMMARY: {
      return {
        ...state,
        summary: action.payload,
      };
    }
    case actionTypes.DELETE_RATINGS: {
      return initialState;
    }
    case actionTypes.DESCRIPTION: {
      return {
        ...state,
        description: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default RatingReducer;
