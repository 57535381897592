/* eslint-disable max-len */
import axios from 'axios';
import { store } from 'store';
import { ENDPOINTS } from './endpoints';

// -----***** DEPLOYMENT SETUP *****-----

// For deployment (default): Uses the deployed frontend URL.
// Uncomment the local testing setup below when using the dev server,
// and **comment this out** before testing locally.

// -----***** DEPLOYMENT SETUP *****-----

const API = axios.create({
  baseURL: `${process.env.REACT_APP_FRONTEND_URL}/platform/`,
});

// -----***** LOCAL TESTING SETUP *****-----

// Local testing (Uncomment this for local development and **comment the above API**):
// Before pushing to the repo, make sure to comment this out and use the deployment setup.

// -----***** LOCAL TESTING SETUP *****-----

// const API = axios.create({
//   baseURL: `http://${process.env.REACT_APP_SERVICES_PLATFORM_ADDRESS}:${process.env.REACT_APP_SERVICES_PLATFORM_PORT}/`,
// });

API.interceptors.request.use(
  (config) => {
    const { isOnline } = store.getState().platform;

    if (!isOnline) {
      return Promise.reject(
        new axios.Cancel('Request blocked: No Internet Connection')
      );
    }

    return config;
  },
  (error) => Promise.reject(error)
);

const addReactionOnArticle = (articleId, reactionValue, token) =>
  API.post(
    ENDPOINTS.add_reaction_on_article(articleId, reactionValue),
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

const addCommentOnArticle = (articleId, payload, token) =>
  API.post(ENDPOINTS.add_commentOnArticle(articleId), payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

const initiateTransaction = (payload, token) =>
  API.post(ENDPOINTS.create_order, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

const buildPlatformConfig = () => API.get(ENDPOINTS.get_platform_config);

const getArticle = (articleId) => API.get(ENDPOINTS.get_article(articleId));

const getCommentsforArticle = (page, size, articleId) =>
  API.get(ENDPOINTS.get_comments_for_article(page, size, articleId));

const getTransactions = (token) =>
  API.get(ENDPOINTS.get_transaction_detail, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

const platformSearch = (query) => API.get(ENDPOINTS.platform_search(query));

const platformSearchResults = (page, size, query, token) =>
  API.get(ENDPOINTS.platform_search_results(page, size, query), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

const userCampaign = (payload) =>
  API.post(ENDPOINTS.add_user_campaign, payload);

export {
  addCommentOnArticle,
  addReactionOnArticle,
  buildPlatformConfig,
  getArticle,
  getCommentsforArticle,
  getTransactions,
  initiateTransaction,
  platformSearch,
  platformSearchResults,
  userCampaign,
};
