import axios from 'axios';
import { store } from 'store';
import { ENDPOINTS } from './endpoints';

// -----***** DEPLOYMENT SETUP *****-----

// For deployment (default): Uses the deployed frontend URL.
// Uncomment the local testing setup below when using the dev server,
// and **comment this out** before testing locally.

// -----***** DEPLOYMENT SETUP *****-----

const API = axios.create({
  baseURL: `${process.env.REACT_APP_FRONTEND_URL}/platform-analytics/`,
});

// -----***** LOCAL TESTING SETUP *****-----

// Local testing (Uncomment this for local development and **comment the above API**):
// Before pushing to the repo, make sure to comment this out and use the deployment setup.

// -----***** LOCAL TESTING SETUP *****-----

// const API = axios.create({
//   baseURL: `http://${process.env.REACT_APP_SERVICES_PLATFORM_ANALYTICS_ADDRESS}:${process.env.REACT_APP_SERVICES_PLATFORM_ANALYTICS_PORT}/`,
// });

API.interceptors.request.use(
  (config) => {
    const { isOnline } = store.getState().platform;

    if (!isOnline) {
      return Promise.reject(
        new axios.Cancel('Request blocked: No Internet Connection')
      );
    }

    return config;
  },
  (error) => Promise.reject(error)
);

const getCategoryProductList = (categoryId) =>
  API.get(ENDPOINTS.get_category_product_list_homepage(categoryId));

const getMostUpvotedProduct = (page, size, categoryId) =>
  API.get(ENDPOINTS.get_most_upvoted_product(page, size, categoryId));

const getMostReviewedProduct = (page, size, categoryId) =>
  API.get(ENDPOINTS.get_most_reviewd_product(page, size, categoryId));

const getMostViewedProduct = (page, size, categoryId) =>
  API.get(ENDPOINTS.get_most_viewed_product(page, size, categoryId));

export {
  getCategoryProductList,
  getMostUpvotedProduct,
  getMostReviewedProduct,
  getMostViewedProduct,
};
