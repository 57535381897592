import * as actionTypes from './actionTypes';

const initialState = {
  activeArticleId: 1,
  closable: false,
  duration: 4000,
  isOnline: navigator.onLine,
  message: '',
  persistent: false,
  platformSearchInputValue: '',
  show: false,
  type: '',
};

// eslint-disable-next-line default-param-last
export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PLATFORM_SEARCH: {
      return {
        ...state,
        platformSearchInputValue: action.payload,
      };
    }
    case actionTypes.CLEAR_PLATFORM_SEARCH_INPUT_VALUE: {
      return {
        ...state,
        platformSearchInputValue: action.payload,
      };
    }
    case actionTypes.ACTIVE_ARTICLE: {
      return {
        ...state,
        activeArticleId: action.payload,
      };
    }
    case actionTypes.SHOW_TOAST:
      return {
        ...state,
        ...action.payload,
        show: true,
      };
    case actionTypes.HIDE_TOAST:
      return {
        ...state,
        show: false,
        message: '',
        type: '',
        duration: 3000,
        persistent: false,
      };
    case actionTypes.SET_ONLINE_STATUS:
      return { ...state, isOnline: action.payload };
    default: {
      return state;
    }
  }
};
