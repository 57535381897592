import React, { createContext, useState, useMemo, useContext } from 'react';

const AdTrayContext = createContext();

export function AdTrayProvider({ children }) {
  const [showAdTray, setShowAdTray] = useState(true);

  const contextValue = useMemo(
    () => ({ showAdTray, setShowAdTray }),
    [showAdTray, setShowAdTray]
  );

  return (
    <AdTrayContext.Provider value={contextValue}>
      {children}
    </AdTrayContext.Provider>
  );
}

export const useAdTray = () => useContext(AdTrayContext);
