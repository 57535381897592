import styled from 'styled-components';
import { DESIGN_CONFIG } from 'utils';

export const TextStyled = styled.p`
  align-items: ${({ alignItems }) => alignItems};
  border: ${({ border }) => border};
  border-radius: ${({ borderRadius }) => borderRadius};
  background: ${({ background }) => background};
  color: ${({ textColor }) => textColor};
  cursor: ${({ cursor }) => cursor};
  display: ${({ display }) => display};
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: ${({ fontSize }) => fontSize};
  font-family: ${({ fontFamily }) => fontFamily};
  font-display: ${({ fontDisplay }) => fontDisplay};
  justify-content: ${({ justifyContent }) => justifyContent};
  left: ${({ left }) => left};
  line-height: ${({ lineHeight }) => lineHeight};
  margin: ${({ margin }) => margin};
  margin-left: ${({ marginLeft }) => marginLeft};
  margin-right: ${({ marginRight }) => marginRight};
  margin-top: ${({ marginTop }) => marginTop};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  max-width: ${({ maxW }) => maxW};
  opacity: ${({ opacity }) => opacity};
  position: ${({ position }) => position};
  padding-top: ${({ paddingTop }) => paddingTop};
  padding: ${({ padding }) => padding};
  text-align: ${({ textAlign }) => textAlign};
  text-decoration: ${({ textDecoration }) => textDecoration};
  text-transform: ${({ textTransform }) => textTransform};
  top: ${({ top }) => top};
  transition: ${({ transition }) => transition};
  word-break: ${({ wordBreak }) => wordBreak};

  ${({ gradients }) =>
    gradients &&
    ` {
      background: ${DESIGN_CONFIG.color.gradients.rainbowEffect};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      }
  `}

  ${({ hover }) =>
    hover &&
    `
    &:hover {
        color: ${DESIGN_CONFIG.color.primary.primaryMain};
      }
  `}

  ${({ isMedia }) =>
    isMedia &&
    `
  @media (max-width: 768px) {
    font-size: ${isMedia};
  }
  `}
`;
